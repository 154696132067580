// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vS3FkMXqgccKN4sb1T82 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
}

.DXoMXJhKppuPCGryHzga {
  min-width: 290px;
  min-height: 400px;
  overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/MercadoPagoPayout/MercadoPagoPayout.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  gap: 16px;\n}\n\n.bricks {\n  min-width: 290px;\n  min-height: 400px;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `vS3FkMXqgccKN4sb1T82`,
	"bricks": `DXoMXJhKppuPCGryHzga`
};
export default ___CSS_LOADER_EXPORT___;
