import React, { useEffect } from "react";
import { Form } from "../interfaces/IModalPlanEdit.interface";

import { isEmailValid } from "../functions/handleBlur";

export const useButtonDisabled = ({
  form,
  setButtonDisabled,
}: {
  form: Form;
  setButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  useEffect(() => {
    if (
      form.identityNumber !== "" &&
      form.identityName !== "" &&
      form.email !== "" &&
      isEmailValid(form.email) &&
      form.departmentId &&
      form.provinceId &&
      form.districtId &&
      form.address !== ""
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);
};
