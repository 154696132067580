import { useEffect } from "react";
import { Error, Form } from "../interfaces/IModalPlanEdit.interface";

export const useErrorReset = ({
  form,
  setError,
}: {
  form: Form;
  setError: React.Dispatch<React.SetStateAction<Error>>;
}) => {
  useEffect(() => {
    setError((prevForm: Error) => ({
      ...prevForm,
      identityNumber: "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.identityNumber]);
};
