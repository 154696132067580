// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --background-header-basico: #45348e;
  --background-body-basico: #6b55c5;
  --background-header-sugerido: #9421e0;
  --background-body-sugerido: #af3ff9;
  --background-header-premium: #01a9f8;
  --background-body-premium: #4cc6ff;
}

.pXT0hLZZLlJK9OcF30sY {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 12px;
  overflow: hidden;
  height: 100%;
  width: 100%;
  border: 1px solid #f7f7f7;
}

.MJfiXlITOIqPxsCIajF8 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 8px 16px;
  background-color: #7c7c7c;
}

.FzDEwDl4KrcuvsCRa2A7 {
  color: #ffffff;
  font: 600 0.825rem "Mulish", sans-serif;
}

.ZEk3lJdVYjHco9Kv9bhp {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 12px 16px 16px;
  height: 100%;
  width: 100%;
  background-color: #fcfcfc;
}

.F_k1LqoIthGXyzPI1OfA {
  color: #ffffff;
  font: 800 1.25rem "Mulish", sans-serif;
}

.ILZSLBbNrtTubg12mLd3 {
  color: #ffffff;
  font: 600 1rem "Mulish", sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/pages/UpdatePlan/components/SimpleFeaturesCard/SimpleFeaturesCard.module.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,iCAAiC;EACjC,qCAAqC;EACrC,mCAAmC;EACnC,oCAAoC;EACpC,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;EACZ,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,WAAW;EACX,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,uCAAuC;AACzC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,uBAAuB;EACvB,SAAS;EACT,uBAAuB;EACvB,YAAY;EACZ,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,sCAAsC;AACxC;;AAEA;EACE,cAAc;EACd,mCAAmC;AACrC","sourcesContent":[":root {\n  --background-header-basico: #45348e;\n  --background-body-basico: #6b55c5;\n  --background-header-sugerido: #9421e0;\n  --background-body-sugerido: #af3ff9;\n  --background-header-premium: #01a9f8;\n  --background-body-premium: #4cc6ff;\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  border-radius: 12px;\n  overflow: hidden;\n  height: 100%;\n  width: 100%;\n  border: 1px solid #f7f7f7;\n}\n\n.header {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  width: 100%;\n  padding: 8px 16px;\n  background-color: #7c7c7c;\n}\n\n.title {\n  color: #ffffff;\n  font: 600 0.825rem \"Mulish\", sans-serif;\n}\n\n.body {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  gap: 10px;\n  padding: 12px 16px 16px;\n  height: 100%;\n  width: 100%;\n  background-color: #fcfcfc;\n}\n\n.body__title {\n  color: #ffffff;\n  font: 800 1.25rem \"Mulish\", sans-serif;\n}\n\n.body__price {\n  color: #ffffff;\n  font: 600 1rem \"Mulish\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `pXT0hLZZLlJK9OcF30sY`,
	"header": `MJfiXlITOIqPxsCIajF8`,
	"title": `FzDEwDl4KrcuvsCRa2A7`,
	"body": `ZEk3lJdVYjHco9Kv9bhp`,
	"body__title": `F_k1LqoIthGXyzPI1OfA`,
	"body__price": `ILZSLBbNrtTubg12mLd3`
};
export default ___CSS_LOADER_EXPORT___;
