import styles from "./styles/ContinueCancelation.module.css";

const ContinueCancelation = () => {
  return (
    <section className={styles.content}>
      <p>
        Prikly te permite gestionar eficientemente tus negocios. Si cancelas tu
        suscripción, perderás acceso a todas las funciones premium y los datos
        personalizados de tus reportes se eliminarán.
      </p>
      <p>
        ¿Estás seguro de que deseas proceder? Deberás completar una breve
        encuesta para finalizar la cancelación.
      </p>
    </section>
  );
};

export default ContinueCancelation;
