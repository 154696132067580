import { useEffect, useState } from "react";
import Profile from "../Profile/Profile";

const AccountInfoCard = () => {
  const [accountInfo, setAccountInfo] = useState(null);

  useEffect(() => {
    const storedData = localStorage.getItem("account_info");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setAccountInfo(parsedData);
    }
  }, []);

  if (!accountInfo) {
    return <div>Cargando...</div>;
  }

  const { employee, company, role } = accountInfo;

  return (
    <Profile
      name={`${employee.name} ${employee.lastName}`}
      email={employee.email}
      phone={employee.phone || "Teléfono no registrado"}
      role={role.name}
      company={company.name}
    />
  );
};

export default AccountInfoCard;
