import React, { createContext, useMemo, useState } from "react";

export interface ReportModalStateContextProps {
  title: string | Element;
  handleTitleChange: Function;
  visibility: {
    isVisible: boolean;
    setIsVisible: Function | null;
  };
  handleVisibilityChange: Function;
  showButtons: {
    showButtonClose: boolean;
    showButtonOne: boolean;
    showButtonTwo: boolean;
  };
  handleShowButtonsChange: Function;
  buttonOne?: {
    textButtonOne: string;
    actionButtonOne: () => void;
    isDisabled?: boolean;
    isLoading?: boolean;
  };
  handleButtonOneChange?: Function;
  buttonTwo?: {
    textButtonTwo: string;
    actionButtonTwo: () => void;
    isDisabled?: boolean;
    isLoading?: boolean;
  };
  handleButtonTwoChange?: Function;
  children: Element | null;
  modalChildren: any;
  handleChildrenChange: Function;
  actionButtonClose: Function | null;
  handleActionButtonCloseChange: Function;
  noSizeLimit: boolean;
  handleNoSizeLimitChange: Function;
  handleClose: Function;
}

const ReportModalStateContext = createContext<
  ReportModalStateContextProps | {}
>({});

const ReportModalStateProvider = ({ children }) => {
  const [title, setTitle] = useState<string | Element>("");
  const [visibility, setVisibility] = useState<{
    isVisible: boolean;
    setIsVisible: Function | null;
  }>({
    isVisible: false,
    setIsVisible: () => {},
  });
  const [showButtons, setShowButtons] = useState<{
    showButtonClose: boolean;
    showButtonOne: boolean;
    showButtonTwo: boolean;
  }>({
    showButtonClose: false,
    showButtonOne: true,
    showButtonTwo: false,
  });
  const [buttonTwo, setButtonTwo] = useState({
    textButtonTwo: "",
    actionButtonTwo: () => {},
    isDisabled: false,
    isLoading: false,
  });
  const [modalChildren, setModalChildren] = useState<any>(null);
  const [actionButtonClose, setActionButtonClose] = useState<Function>(
    () => null
  );
  const [noSizeLimit, setNoSizeLimit] = useState(false);

  const handleTitleChange = (title: string | Element) => {
    setTitle(title);
  };

  const handleVisibilityChange = (visibility: {
    isVisible: boolean;
    setIsVisible: Function | null;
  }) => {
    setVisibility(visibility);
  };

  const handleShowButtonsChange = (showButtons: {
    showButtonClose: boolean;
    showButtonOne: boolean;
    showButtonTwo: boolean;
  }) => {
    setShowButtons(showButtons);
  };

  const handleButtonTwoChange = (buttonTwo: {
    textButtonTwo: string;
    actionButtonTwo: () => void;
    isDisabled?: boolean;
    isLoading?: boolean;
  }) => {
    // @ts-ignore
    setButtonTwo(buttonTwo);
  };

  const handleChildrenChange = (modalChildren: any) => {
    setModalChildren(modalChildren);
  };

  const handleActionButtonCloseChange = (actionButtonClose: Function) => {
    setActionButtonClose(() => actionButtonClose);
  };

  const handleNoSizeLimitChange = (noSizeLimit: boolean) => {
    setNoSizeLimit(noSizeLimit);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClose = (callback: () => void = () => {}) => {
    handleVisibilityChange({
      isVisible: false,
      setIsVisible: visibility.setIsVisible,
    });
    callback();
  };

  const contextValue = useMemo(
    () => ({
      title,
      visibility,
      showButtons,
      buttonTwo,
      modalChildren,
      actionButtonClose,
      noSizeLimit,
      handleTitleChange,
      handleVisibilityChange,
      handleShowButtonsChange,
      handleButtonTwoChange,
      handleChildrenChange,
      handleActionButtonCloseChange,
      handleNoSizeLimitChange,
      handleClose,
    }),
    [
      title,
      visibility,
      showButtons,
      buttonTwo,
      modalChildren,
      actionButtonClose,
      noSizeLimit,
      handleClose,
    ]
  );

  return (
    <ReportModalStateContext.Provider value={contextValue}>
      {children}
    </ReportModalStateContext.Provider>
  );
};

export { ReportModalStateContext, ReportModalStateProvider };
