import { Error } from "../interfaces/IModalPlanEdit.interface";

export const handleBlurDNINumber = (
  e: React.ChangeEvent<HTMLInputElement>,
  setError: (value: any) => void
) => {
  const { name, value } = e.target;
  if (value.length === 0) {
    setError((prevError: Error) => ({
      ...prevError,
      [name]: "Este campo es obligatorio",
    }));
    return;
  }
  if (value.length !== 8) {
    setError((prevError: Error) => ({
      ...prevError,
      [name]: "El DNI debe tener 8 dígitos",
    }));
    return;
  } else {
    setError((prevError: Error) => ({
      ...prevError,
      [name]: "",
    }));
  }
};

export const handleBlurRUCNumber = (
  e: React.ChangeEvent<HTMLInputElement>,
  setError: (value: any) => void
) => {
  const { name, value } = e.target;
  if (value.length === 0) {
    setError((prevError: Error) => ({
      ...prevError,
      [name]: "Este campo es obligatorio",
    }));
    return;
  } else if (value.length !== 11) {
    setError((prevError: Error) => ({
      ...prevError,
      [name]: "El RUC debe tener 11 dígitos",
    }));
    return;
  } else {
    setError((prevError: Error) => ({
      ...prevError,
      [name]: "",
    }));
  }
};

export const handleBlurName = (
  e: React.ChangeEvent<HTMLInputElement>,
  setError: (value: any) => void
) => {
  const { name, value } = e.target;
  if (value.trim().length === 0) {
    setError((prevError: Error) => ({
      ...prevError,
      [name]: "Este campo es obligatorio",
    }));
    return;
  } else {
    setError((prevError: Error) => ({
      ...prevError,
      [name]: "",
    }));
  }
};
export const handleBlurReasonSocial = (
  e: React.ChangeEvent<HTMLInputElement>,
  setError: (value: any) => void
) => {
  const { name, value } = e.target;
  if (value.trim().length === 0) {
    setError((prevError: Error) => ({
      ...prevError,
      [name]: "Este campo es obligatorio",
    }));
    return;
  } else {
    setError((prevError: Error) => ({
      ...prevError,
      [name]: "",
    }));
  }
};

export const isEmailValid = (email: string) => {
  const emailRegex = /^([\w.%+-ñÑ]+)@([\w-ñÑ]+\.)+([\wñÑ]{2,})$/i;
  return emailRegex.test(email);
};

export const handleBlurEmail = (
  e: React.ChangeEvent<HTMLInputElement>,
  setError: (value: any) => void
) => {
  const { name, value } = e.target;
  if (value.trim().length === 0) {
    setError((prevError: Error) => ({
      ...prevError,
      [name]: "Este campo es obligatorio",
    }));
    return;
  }
  if (!isEmailValid(value)) {
    setError((prevError: Error) => ({
      ...prevError,
      [name]: "Correo electrónico inválido",
    }));
    return;
  } else {
    setError((prevError: Error) => ({
      ...prevError,
      [name]: "",
    }));
  }
};

export const handleBlurDepartment = (
  e: React.ChangeEvent<HTMLSelectElement>,
  setError: (value: any) => void
) => {
  const { name, value } = e.target;
  if (value === "") {
    setError((prevError: Error) => ({
      ...prevError,
      [name]: "Este campo es obligatorio",
    }));
    return;
  } else {
    setError((prevError: Error) => ({
      ...prevError,
      [name]: "",
    }));
  }
};

export const handleBlurProvince = (
  e: React.ChangeEvent<HTMLSelectElement>,
  setError: (value: any) => void
) => {
  const { name, value } = e.target;
  if (value === "") {
    setError((prevError: Error) => ({
      ...prevError,
      [name]: "Este campo es obligatorio",
    }));
    return;
  } else {
    setError((prevError: Error) => ({
      ...prevError,
      [name]: "",
    }));
  }
};

export const handleBlurDistrict = (
  e: React.ChangeEvent<HTMLSelectElement>,
  setError: (value: any) => void
) => {
  const { name, value } = e.target;
  if (value === "") {
    setError((prevError: Error) => ({
      ...prevError,
      [name]: "Este campo es obligatorio",
    }));
    return;
  } else {
    setError((prevError: Error) => ({
      ...prevError,
      [name]: "",
    }));
  }
};

export const handleBlurAddress = (
  e: React.ChangeEvent<HTMLInputElement>,
  setError: (value: any) => void
) => {
  const { name, value } = e.target;
  if (value.length === 0) {
    setError((prevError: Error) => ({
      ...prevError,
      [name]: "Este campo es obligatorio",
    }));
    return;
  } else {
    setError((prevError: Error) => ({
      ...prevError,
      [name]: "",
    }));
  }
};
