import { createElectronicBillingPayment_service } from "@Services/electronicBilling/createElectronicBillingPayment";

export const createElectronicBillingPayment = async (data: any, item) => {
  const payload = {
    paymentMethodId: data.payment_method_id,
    issuerId: data.issuer_id,
    cardholderEmail: data.payer.email,
    token: data.token,
    installments: data.installments,
    identificationNumber: data.payer.identification.number,
    identificationType: data.payer.identification.type,
    packageInoviceDocumentId: item.id,
  };

  const response = await createElectronicBillingPayment_service(payload);

  return response;
};
