// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --background-header-basico: #45348e;
  --background-body-basico: #6b55c5;
  --background-header-sugerido: #9421e0;
  --background-body-sugerido: #af3ff9;
  --background-header-premium: #01a9f8;
  --background-body-premium: #4cc6ff;
}

.vRcwBzDW2r08rMW7YJtI {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 12px;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.IbROWn7YprfmgsEFZgYw {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 12px 16px;
}

.k5XnB33vOepVbNXaNZuT {
  color: #ffffff;
  font: 600 0.825rem "Mulish", sans-serif;
}

.PLoUz4hdMdrXQXiJHEAM {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 12px 16px 16px;
  height: 100%;
  width: 100%;
}

.dEUz3Cq1z4KCZdrANlBh {
  color: #ffffff;
  font: 800 1.25rem "Mulish", sans-serif;
}

.FOcI3N0a8Be6ceYAy1Qk {
  color: #ffffff;
  font: 600 1rem "Mulish", sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/pages/UpdatePlan/components/SimpleSubscriptionCard/SimpleSubscriptionCard.module.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,iCAAiC;EACjC,qCAAqC;EACrC,mCAAmC;EACnC,oCAAoC;EACpC,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,SAAS;EACT,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,uCAAuC;AACzC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,uBAAuB;EACvB,SAAS;EACT,uBAAuB;EACvB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,cAAc;EACd,sCAAsC;AACxC;;AAEA;EACE,cAAc;EACd,mCAAmC;AACrC","sourcesContent":[":root {\n  --background-header-basico: #45348e;\n  --background-body-basico: #6b55c5;\n  --background-header-sugerido: #9421e0;\n  --background-body-sugerido: #af3ff9;\n  --background-header-premium: #01a9f8;\n  --background-body-premium: #4cc6ff;\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  border-radius: 12px;\n  overflow: hidden;\n  height: 100%;\n  width: 100%;\n}\n\n.header {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 10px;\n  width: 100%;\n  padding: 12px 16px;\n}\n\n.title {\n  color: #ffffff;\n  font: 600 0.825rem \"Mulish\", sans-serif;\n}\n\n.body {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  gap: 10px;\n  padding: 12px 16px 16px;\n  height: 100%;\n  width: 100%;\n}\n\n.body__title {\n  color: #ffffff;\n  font: 800 1.25rem \"Mulish\", sans-serif;\n}\n\n.body__price {\n  color: #ffffff;\n  font: 600 1rem \"Mulish\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `vRcwBzDW2r08rMW7YJtI`,
	"header": `IbROWn7YprfmgsEFZgYw`,
	"title": `k5XnB33vOepVbNXaNZuT`,
	"body": `PLoUz4hdMdrXQXiJHEAM`,
	"body__title": `dEUz3Cq1z4KCZdrANlBh`,
	"body__price": `FOcI3N0a8Be6ceYAy1Qk`
};
export default ___CSS_LOADER_EXPORT___;
