import {
  ButtonWithoutIcon,
  // @ts-ignore
} from "@viuti/recursos";

import styles from "./styles/ModalFooter.module.css";

export interface IFooterProps {
  buttonLeft?: IResourcesBttnProps;
  buttonRight?: IResourcesBttnProps;
}

interface IResourcesBttnProps {
  textBttn: string;
  handleClick?: () => void;
  isDisabled?: boolean;
  isHidden?: boolean;
  isPrimary?: boolean;
  isLoading?: boolean;
}

const ModalFooter: React.FC<IFooterProps> = ({ buttonLeft, buttonRight }) => {
  return (
    <footer className={styles.modalFooter__container}>
      {buttonLeft ? <ButtonWithoutIcon {...buttonLeft} /> : <div></div>}
      {buttonRight && <ButtonWithoutIcon {...buttonRight} />}
    </footer>
  );
};

export default ModalFooter;
