import { HiChatBubbleBottomCenterText } from "react-icons/hi2";
import styles from "./styles/CancelConfirmed.module.css";

const CancelConfirmed = () => {
  return (
    <section>
      <p className={styles.bodyModal__message}>
        Tu suscripción ha sido cancelada y tu cuenta se eliminará muy pronto.
      </p>
      <section className={styles.bodyModal__warning}>
        <HiChatBubbleBottomCenterText size={22} color="#45348E" />
        <article className={styles.bodyModal__warning__message}>
          <strong>¡Lo lamentamos!</strong>
          <p>
            Hemos enviado un correo de confirmación a tu dirección registrada.
            Gracias por haber formado parte de Prikly. Te invitamos a regresar
            pronto.
          </p>
        </article>
      </section>
    </section>
  );
};

export default CancelConfirmed;
