// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bmmdHYUCccLjmQ8o1Wh6 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.qBTWuOhSqmndEapddrIF {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 14px;
  height: auto;
  aspect-ratio: 1;
  border-radius: 50%;
}

.HCvi2vvap9Jk6FwKiNyi {
  color: #000000;
  font: 600 0.875rem "Mulish", sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/FeatureItem/FeatureItem.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,uCAAuC;AACzC","sourcesContent":[".feature {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 10px;\n}\n\n.featureIconContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-width: 14px;\n  height: auto;\n  aspect-ratio: 1;\n  border-radius: 50%;\n}\n\n.featureName {\n  color: #000000;\n  font: 600 0.875rem \"Mulish\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"feature": `bmmdHYUCccLjmQ8o1Wh6`,
	"featureIconContainer": `qBTWuOhSqmndEapddrIF`,
	"featureName": `HCvi2vvap9Jk6FwKiNyi`
};
export default ___CSS_LOADER_EXPORT___;
