import style from "./PackageList.module.css";
// @ts-ignore
import { CircularRadioInput } from "@viuti/recursos";
import { formatToSoles } from "@Utilities/FormatsHandler";

const PackageList = ({ availablePackages, selectedPackage, handleChange }) => (
  <>
    {availablePackages?.map((plan) => (
      <button
        className={
          selectedPackage?.id === plan.id ? style.selected : style.card
        }
        key={plan.id}
        onClick={() => handleChange(plan.id)}
      >
        <div className={style.containerContent}>
          <CircularRadioInput
            id={plan.id}
            checked={selectedPackage?.id === plan.id}
            onClick={null}
          />
          <h3 className={style.total}>{plan.name}</h3>
        </div>
        <span className={style.amount}>
          {formatToSoles(plan.price.withoutIGV)} + IGV
        </span>
      </button>
    ))}
  </>
);

export default PackageList;
