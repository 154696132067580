import { getSearchReniec_service } from "@Services/reniec/getSearchReniec.service";

export const getSearchReniec_adapter = async (documentNumber: string) => {
  let response = await getSearchReniec_service(documentNumber);

  if (
    response.isSuccess &&
    response.data.mensaje === "Error al buscar al cliente."
  ) {
    response.isSuccess = false;
  }

  if (!response.isSuccess) {
    return {
      ...response,
      data: {
        reasonSocial: "",
        address: "",
      },
    };
  }
  return {
    ...response,
    data: {
      reasonSocial: removeQuotes(response.data.nombreNegocio),
      address: isNull(response.data.direccion),
    },
  };
};

const removeQuotes = (str: string) => {
  if (str === null) {
    return "";
  }
  if (
    (str.startsWith('"') && str.endsWith('"')) ||
    (str.startsWith("'") && str.endsWith("'"))
  ) {
    return str.slice(1, -1);
  }
  return str;
};

const isNull = (str: string | null): string => {
  return str ?? "";
};
