import { createAnnualSubscriptionPayment } from "@Adapters/suscriptions/createAnnualSubscriptionPayment";
import { createSubscriptionPayment } from "@Adapters/suscriptions/createSubscriptionPayment";
import { ReportModalStateContextProps } from "@Hooks/ReportModalStateContext";
import { ISubscription, ISubscriptionPlan } from "@Models/interfaces/plan";
import { BASE_ROUTE } from "@Models/routes";
import { useEffect, useState } from "react";

interface IPaymentResponse {
  status: string;
  message: string;
  loading: boolean;
  error: boolean;
}

export const usePlanPaymentHandler = (
  modalContext: ReportModalStateContextProps,
  plan: ISubscriptionPlan,
  subscription: ISubscription
) => {
  const [paymentResponse, setPaymentResponse] =
    useState<IPaymentResponse>(null);

  useEffect(() => {
    modalContext.handleShowButtonsChange({
      ...modalContext.showButtons,
      showButtonTwo: false,
    });
    modalContext.handleActionButtonCloseChange(() => {
      setPaymentResponse(null);
      modalContext.handleVisibilityChange({
        isVisible: false,
        setIsVisible: () => {},
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaymentResponse = (response) => {
    const isSuccess = response.ok || response.isSuccess || false;
    const newResponse = {
      status: response.status,
      message: response.message,
      loading: false,
      error: !isSuccess,
    };
    setPaymentResponse(newResponse);
  };

  const handleCreatePayment = async (response) => {
    setPaymentResponse({
      status: "",
      message: "",
      loading: true,
      error: false,
    });
    modalContext.handleShowButtonsChange({
      ...modalContext.showButtons,
      showButtonTwo: false,
    });
    let creationResponse;
    if (subscription.isAnually) {
      creationResponse = await createAnnualSubscriptionPayment(
        response,
        plan,
        subscription
      );
    } else
      creationResponse = await createSubscriptionPayment(
        response,
        plan,
        subscription
      );
    if (creationResponse.isSuccess) {
      modalContext.handleActionButtonCloseChange(() => {
        window.location.href = BASE_ROUTE;
        modalContext?.actionButtonClose();
      });
    }
    handlePaymentResponse(creationResponse);
  };

  const handleTryAgain = () => {
    setPaymentResponse(null);
    modalContext.handleShowButtonsChange({
      ...modalContext.showButtons,
      showButtonTwo: true,
    });
  };

  return {
    paymentResponse,
    handleCreatePayment,
    handlePaymentResponse,
    handleTryAgain,
  };
};
