import { HiExclamationTriangle } from "react-icons/hi2";
import styles from "./styles/CancelSubscriptionConfirmation.module.css";

const CancelSubscriptionConfirmation: React.FC = () => {
  return (
    <div className={styles.bodyModal__container}>
      <p>
        Por favor, descarga la información de tus comprobantes electrónicos
        antes de continuar.
      </p>

      <section className={styles.bodyModal__warning}>
        <HiExclamationTriangle size={22} color="#DE372F" />
        <article className={styles.bodyModal__warning__message}>
          <strong>Importante</strong>
          <p>
            Una vez terminada la suscripción, no almacenaremos tus comprobantes
            electrónicos
          </p>
        </article>
      </section>

      <section className={styles.bodyModal__caution}>
        <strong>¡Cuidado!</strong>
        <p>
          Tu suscripción continuará activa hasta el 26 de Agosto de 2024. Luego
          de esta fecha, tu cuenta será dada de baja.
        </p>
      </section>
    </div>
  );
};

export default CancelSubscriptionConfirmation;
