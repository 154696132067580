// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vNpGNKp4GJZHyVj03Od8 {
  width: 100%;
  padding: 10px 16px;
  border-radius: 6px;
  border: 1px solid #e6e8eb;
  display: grid;
  grid-template-columns: 24px auto;
  gap: 8px;
}

.Q8Op9ZaX3Pr3NckrciMr {
  display: grid;
  gap: 6px;
}

.fFPK85Eb1QmVX53xYBVG {
  display: grid;
  gap: 16px;

  & p {
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    text-align: left;
  }

  & strong {
    font-weight: 700;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManageSubscription/view/DiscountOffer/styles/DiscountOffer.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,gCAAgC;EAChC,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,SAAS;;EAET;IACE,iCAAiC;IACjC,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".bodyModal__warning {\n  width: 100%;\n  padding: 10px 16px;\n  border-radius: 6px;\n  border: 1px solid #e6e8eb;\n  display: grid;\n  grid-template-columns: 24px auto;\n  gap: 8px;\n}\n\n.bodyModal__warning__message {\n  display: grid;\n  gap: 6px;\n}\n\n.bodyModal__container {\n  display: grid;\n  gap: 16px;\n\n  & p {\n    font-family: \"Mulish\", sans-serif;\n    font-weight: 400;\n    text-align: left;\n  }\n\n  & strong {\n    font-weight: 700;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bodyModal__warning": `vNpGNKp4GJZHyVj03Od8`,
	"bodyModal__warning__message": `Q8Op9ZaX3Pr3NckrciMr`,
	"bodyModal__container": `fFPK85Eb1QmVX53xYBVG`
};
export default ___CSS_LOADER_EXPORT___;
