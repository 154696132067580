import React, { useEffect } from "react";
import style from "./MercadoPagoPayout.module.css";
// @ts-ignore
import { ButtonWithoutIcon } from "@viuti/recursos";

interface IMercadoPagoPayoutProps {
  handleGoBack?: () => void;
  title: string;
  initialization: {
    amount: number;
    payer?: {
      email?: string;
    };
  };
  onMeliSuccess: (response: any) => void;
  onMeliError: (reject: any) => void;
  tempType?: string;
}
const MercadoPagoCardPayment = ({
  handleGoBack,
  title,
  initialization,
  onMeliSuccess,
  onMeliError,
  tempType,
}: IMercadoPagoPayoutProps) => {
  let PUBLIC_KEY = "";

  switch (tempType) {
    case "compra":
      PUBLIC_KEY = "APP_USR-3a557844-2890-4828-926e-30de00d3517d";
      break;

    case "suscripcion":
      PUBLIC_KEY = "APP_USR-3a557844-2890-4828-926e-30de00d3517d";
      break;

    default:
      break;
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://sdk.mercadopago.com/js/v2";
    script.onload = () => {
      // @ts-ignore
      const mp = new window.MercadoPago(PUBLIC_KEY, {
        locale: "es-PE",
      });
      const bricksBuilder = mp.bricks();
      const renderCardPaymentBrick = async (bricksBuilder) => {
        const settings = {
          initialization: {
            amount: initialization.amount, // monto a ser pago
            payer: {
              email: initialization.payer.email || "",
            },
          },
          customization: {
            visual: {
              style: {
                customVariables: {
                  theme: "default", // | 'dark' | 'bootstrap' | 'flat'
                  formPadding: "6px",
                  fontSizeSmall: "0.875rem",
                  fontSizeMedium: "0.9rem",
                  fontSizeLarge: "1rem",
                },
              },
              texts: {
                formTitle: title,
                formSubmit: "Pagar",
                emailSectionTitle: " ",
              },
            },
            paymentMethods: {
              maxInstallments: 1,
            },
          },
          callbacks: {
            onReady: () => {
              // callback llamado cuando Brick esté listo
              const iframe = document.querySelector(
                "#cardPaymentBrick_container iframe"
              );
              if (iframe) {
                const iframeDoc =
                  //@ts-ignore
                  iframe.contentDocument || iframe.contentWindow.document;
                const inputs = iframeDoc.querySelectorAll("input");
                inputs.forEach((input) => {
                  input.style.fontSize = "14px";
                });
              }
            },
            onSubmit: async (cardFormData) => {
              onMeliSuccess(cardFormData);
            },
            onError: (error) => {
              if (error.type !== "non_critical") onMeliError(error);
            },
          },
        };
        // @ts-ignore
        window.cardPaymentBrickController = await bricksBuilder.create(
          "cardPayment",
          "cardPaymentBrick_container",
          settings
        );
      };
      renderCardPaymentBrick(bricksBuilder);
    };
    document.body.appendChild(script);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.container}>
      <div id="cardPaymentBrick_container" className={style.bricks}></div>
      {handleGoBack && (
        <ButtonWithoutIcon
          isPrimary={false}
          textBttn={"Volver"}
          handleClick={() => handleGoBack()}
          width="100%"
        />
      )}
    </div>
  );
};

export default MercadoPagoCardPayment;
