// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FNPdyPiFFskP5vZuLCWl {
  display: grid;
  gap: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManageSubscription/view/ContinueCancelation/styles/ContinueCancelation.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX","sourcesContent":[".content {\n  display: grid;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `FNPdyPiFFskP5vZuLCWl`
};
export default ___CSS_LOADER_EXPORT___;
