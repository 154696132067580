import React, { useState } from "react";
import style from "./BillingCard.module.css";
import {
  ButtonWithoutIcon,
  NoInfoToShow,
  Icon,
  DualBars,
  validateModuleAccess,
  // @ts-ignore
} from "@viuti/recursos";
import { ModalPlanEdit } from "./ModalPlanEdit/ModalPlanEdit";
import { DocumentIcon } from "@Models/icons";
import { IBillingStore, useBillingStore } from "@Stores/useBillingStore";
import { MODIFY_BILLING_INFO_UUID } from "@Models/const/securityAccess";

interface IBillingCard {
  isLoading: boolean;
  hasError: boolean;
  successCallback?: () => void;
}

const BillingCard: React.FC<IBillingCard> = ({
  isLoading,
  hasError,
  successCallback,
}) => {
  const { billingInformation } = useBillingStore() as IBillingStore;

  const [modalIsVisible, setModalIsVisible] = useState(false);

  const titleMessage = !hasError
    ? "No se han completado los datos de facturación"
    : "Hubo un problema al obtener los datos de facturación";

  const message = !hasError
    ? "Para poder continuar con el proceso de compra, por favor completa los datos de facturación."
    : "Por favor, intenta nuevamente más tarde.";

  const isInformationFilled = billingInformation?.id;

  return (
    <div
      className={style.container}
      style={
        hasError || isLoading
          ? { justifyContent: "center" }
          : { justifyContent: "space-between" }
      }
    >
      {isLoading ? (
        <DualBars />
      ) : (
        <>
          {!hasError && <h2>Facturación de Prikly</h2>}
          {isInformationFilled ? (
            <section className={style.billingData}>
              <div className={style.billingItem}>
                <span className={style.billingTitle}>Identificación</span>
                <span className={style.billingValue}>
                  {billingInformation.identity.number}
                </span>
              </div>
              <div className={style.billingItem}>
                <span className={style.billingTitle}>
                  {billingInformation.document.id === 2
                    ? "Nombre y apellido"
                    : "Razón social"}
                </span>
                <span className={style.billingValue}>
                  {billingInformation.identity.name}
                </span>
              </div>
              <div className={style.billingItem}>
                <span className={style.billingTitle}>Email</span>
                <span className={style.billingValue}>
                  {billingInformation.email}
                </span>
              </div>
              <div className={style.billingItem}>
                <span className={style.billingTitle}>Departamento</span>
                <span className={style.billingValue}>
                  {billingInformation.department.name}
                </span>
              </div>
              <div className={style.billingItem}>
                <span className={style.billingTitle}>Provincia</span>
                <span className={style.billingValue}>
                  {billingInformation.province.name}
                </span>
              </div>
              <div className={style.billingItem}>
                <span className={style.billingTitle}>Distrito</span>
                <span className={style.billingValue}>
                  {billingInformation.district.name}
                </span>
              </div>
              <div className={style.billingItem}>
                <span className={style.billingTitle}>Dirección</span>
                <span className={style.billingValue}>
                  {billingInformation.address}
                </span>
              </div>
              <div className={style.billingItem}>
                <span className={style.billingTitle}>Comprobante de envío</span>
                <span className={style.billingValue}>
                  {billingInformation.voucher.name}
                </span>
              </div>
            </section>
          ) : (
            <section>
              <NoInfoToShow
                title={titleMessage}
                message={message}
                error={hasError}
              >
                <Icon
                  path={DocumentIcon}
                  size={30}
                  alt="Error"
                  color={hasError && "#b91616"}
                />
              </NoInfoToShow>
            </section>
          )}
          {!hasError && (
            <ButtonWithoutIcon
              textBttn={
                isInformationFilled
                  ? "Actualizar"
                  : "Completar datos de facturación"
              }
              handleClick={() => {
                validateModuleAccess(MODIFY_BILLING_INFO_UUID) &&
                  setModalIsVisible(true);
              }}
              isPrimary={false}
              width="100%"
            />
          )}
          {modalIsVisible && (
            <ModalPlanEdit
              setModalIsVisible={setModalIsVisible}
              initialValues={billingInformation}
              successCallback={successCallback}
            />
          )}
        </>
      )}
    </div>
  );
};

export default BillingCard;
