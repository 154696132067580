import React from "react";
import style from "./AvailablePlans.module.css";
// @ts-ignore
import { HeaderNavigation, LoadingScreen } from "@viuti/recursos";
import { MercadoPagoIcon } from "@Models/icons";
import SubscriptionPlanCard from "@Components/ui/molecules/SubscriptionPlanCard/SubscriptionPlanCard";
import useAvailablePlans from "@Hooks/useAvailablePlans";
import { IPlanStore, usePlanStore } from "@Stores/usePlanStore";
import { ROUTES } from "@Models/routes";
import { useNavigate } from "react-router-dom";

const AvailablePlans = () => {
  const navigate = useNavigate();
  const { renderPlans, availablePlansFetchResponse } = useAvailablePlans();
  const { currentPlanFetchResponse } = usePlanStore() as IPlanStore;

  return (
    <div className={style.view__container}>
      <HeaderNavigation
        title={"Planes disponibles"}
        previousAction={() => navigate(ROUTES.HOME_ROUTE)}
        previousActionMovement={"back"}
        buttonProps={{
          textBttn: "",
          handleClick: () => {},
          isDisabled: true,
          isPrimary: true,
          isHidden: true,
        }}
      />

      <div className={style.content}>
        <div className={style.header_information}>
          <h1 className={style.header_information__title}>
            Planes accesibles para cada situación
          </h1>
          <span className={style.header_information__description}>
            <p className={style.header_information__message}>
              Elige el plan perfecto para tu negocio y gestiona todo de manera
              eficiente con nuestras herramientas avanzadas. Controla tus
              ventas, inventarios, administra clientes, y genera reportes
              detallados. Cambia o cancela tu plan cuando lo necesites.
            </p>

            <p className={style.header_information__message}>
              ¡Te respaldamos con seguridad y soporte confiable!
              <br />
              Tus compras están protegidas con
            </p>

            <div className={style.logo__container}>
              <img src={MercadoPagoIcon} alt="MercadoPago" />
            </div>
          </span>
        </div>
        <div className={style.plans__container}>
          {renderPlans?.map((plan) => (
            <SubscriptionPlanCard
              key={plan.id}
              {...plan}
              isLoading={
                availablePlansFetchResponse.status === "loading" ||
                currentPlanFetchResponse.status === "loading"
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AvailablePlans;
