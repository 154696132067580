import { useEffect, useState } from "react";
import { getApiDepartaments_adapter } from "@Adapters/locationsAdapters/locationAdapters.adapter";

export const useDepartaments = (
  setCriticalError: any,
  setLoadingLocation: any
) => {
  const [departaments, setDepartaments] = useState([]);

  useEffect(() => {
    (async () => {
      setLoadingLocation(
        "Aguarda un momento mientras cargamos la información..."
      );
      const response = await getApiDepartaments_adapter();
      if (!response.isSuccess) {
        setCriticalError(
          "Hubo un error al cargar los departamentos, por favor vuelve a intentarlo"
        );
      }
      setLoadingLocation("");
      setDepartaments(response.data);
    })();
    // eslint-disable-next-line
  }, []);
  return { departaments };
};
