import React from "react";
import ModalFooter, {
  IFooterProps,
} from "./components/ModalFooter/ModalFooter";
import ModalHeader from "./components/ModalHeader/ModalHeader.component";
import style from "./styles/modalWrapper.module.css";

export interface IModalWrapper {
  title: string;
  children: React.ReactNode;
  footerProps?: IFooterProps;
  isVisible?: boolean;
  onCloseModal?: () => void;
}

const ModalWrapper: React.FC<IModalWrapper> = ({
  title,
  children,
  footerProps,
  isVisible = false,
  onCloseModal = () => {},
}) => {
  if (!isVisible) return null;

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onCloseModal();
    }
  };

  return (
    <section
      className={style.modalWrapper__overlay}
      role="presentation"
      onClick={handleOverlayClick}
      onKeyDown={(e) => e.key === "Escape" && onCloseModal()}
      tabIndex={-1}
    >
      <dialog className={style.modalWrapper__dialog}>
        <ModalHeader title={title} onCloseModal={onCloseModal} />
        <section>{children}</section>
        <ModalFooter {...footerProps} />
      </dialog>
    </section>
  );
};

export default ModalWrapper;
