import React from "react";
import MercadoPagoCardPayment from "@Components/ui/molecules/MercadoPagoPayout/MercadoPagoPayout";

interface IPaymentProcessingProps {
  handleGoBack?: () => void;
  name: string;
  amount: number;
  payer?: {
    email?: string;
  };
  handleCreatePayment: (response: any) => void;
  handlePaymentResponse: (reject: any) => void;
  tempType?: string;
}
const PaymentProcessing: React.FC<IPaymentProcessingProps> = ({
  handleGoBack,
  name,
  amount,
  payer,
  handleCreatePayment,
  handlePaymentResponse,
  tempType,
}) => (
  <MercadoPagoCardPayment
    handleGoBack={handleGoBack}
    title={`Prikly - ${name}`}
    initialization={{ amount: amount, payer: { email: payer?.email } }}
    onMeliSuccess={handleCreatePayment}
    onMeliError={handlePaymentResponse}
    tempType={tempType}
  />
);

export default PaymentProcessing;
