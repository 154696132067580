import { ISubscriptionPlan } from "@Models/interfaces/plan";
import { getSubscriptionPlans_service } from "@Services/subscriptions/getSubscriptionPlans.service";
import { capitalizeFirstLetter } from "@Utilities/FormatsHandler";

interface IParams {
  setAvailablePlans: (availablePlans: ISubscriptionPlan[]) => void;
}

export const getSubscriptionPlans = async (params: IParams) => {
  const { setAvailablePlans } = params;
  const response = await getSubscriptionPlans_service();

  if (!response.isSuccess) return response;

  const subscriptionPlans: ISubscriptionPlan[] = response.data.map(
    (plan: any) => ({
      id: plan.planId,
      category: capitalizeFirstLetter(plan.categoryName),
      name: capitalizeFirstLetter(plan.planName.replace("Plan ", "")),
      users: {
        quantity: plan.users.quantity,
        unlimited: plan.users.unlimited,
      },
      price: plan.periodSuscriptions?.find(
        (subscription) => subscription.monthPeriod === 1
      )?.priceWithoutIGV,
      features: plan.features.map((feature) => ({
        name: feature.name,
        isBoosted: feature.isBoosted,
      })),
      isCurrentPlan: plan.currentPlan,
      subscriptions: plan.periodSuscriptions?.map((subscription) => ({
        id: subscription.periodSuscriptionId,
        mpPreaprovalPlanId: subscription.mpPreaprovalPlanId,
        months: subscription.monthPeriod,
        name: `${subscription.monthPeriod} ${
          subscription.monthPeriod === 1 ? "mes" : "meses"
        }`,
        prices: {
          withIGV: subscription.priceWithIGV,
          withoutIGV: subscription.priceWithoutIGV,
        },
        equalsMonthly: Number(subscription.equalsMonthly),
        saves: Number(subscription.saves),
        isAnually: subscription.annualPlan,
      })),
      hexColors: {
        primary_50: plan.codeColorPrimaryCard,
        primary_70: plan.codeColorChip,
        primary_90: plan.codeColorBodyCard,
      },
    })
  );

  setAvailablePlans(subscriptionPlans);

  return {
    ...response,
    data: subscriptionPlans,
  };
};
