import { accountBaseUrl, getData } from "@Services/constServices";
import { plans } from "../../mocks/plans";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const getSubscriptionPlans_service = async () => {
  const url = `${accountBaseUrl}/Account/PlanServices`;
  const ingresa_vista_planes_disponibles = 54;
  trackUserAction(ingresa_vista_planes_disponibles);
  const successMessage = "Planes de suscripción obtenidos";
  const errorMessage = "Planes de suscripción no obtenidos";
  return getData(url, successMessage, errorMessage);
};
