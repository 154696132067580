import React from "react";
import style from "./FeatureItem.module.css";
import { HiLightningBolt } from "react-icons/hi";
import { FaCheck } from "react-icons/fa6";
import { IPlanFeature } from "@Models/interfaces/plan";
interface FeatureItem extends IPlanFeature {
  backgroundColor: T_HexColor;
}

const FeatureItem: React.FC<FeatureItem> = ({
  name,
  backgroundColor,
  isBoosted,
}) => (
  <span className={style.feature}>
    <span
      className={style.featureIconContainer}
      style={{ backgroundColor: backgroundColor }}
    >
      {isBoosted ? (
        <HiLightningBolt size={8} color="#ffffff" />
      ) : (
        <FaCheck size={7} color="#ffffff" strokeWidth={35} />
      )}
    </span>
    <p className={style.featureName}>{name}</p>
  </span>
);

export default FeatureItem;
