// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --account-layout-gap: 20px;
}

.eiwX7evuNsEnAs9rcPpN {
  display: flex;
  flex-direction: column;
  gap: var(--account-layout-gap);
}

.hMHyJUDrAOmkKSy1izkt {
  display: flex;
  gap: var(--account-layout-gap);
}

.p35wmcY6QovLwRMMh1qW {
  display: flex;
  gap: 1rem;
  width: 500px;
  transition: all 150ms ease-in-out;
}

@media screen and (width <= 730px) {
  .tx7QzEamP9DoYU4ezOGg {
    gap: var(--account-layout-gap);
    flex-direction: column;
  }

  .p35wmcY6QovLwRMMh1qW {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/views/AdminAccount/AdminAccount.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,SAAS;EACT,YAAY;EACZ,iCAAiC;AACnC;;AAEA;EACE;IACE,8BAA8B;IAC9B,sBAAsB;EACxB;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":[":root {\n  --account-layout-gap: 20px;\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n  gap: var(--account-layout-gap);\n}\n\n.row {\n  display: flex;\n  gap: var(--account-layout-gap);\n}\n\n.suscription_actions__container {\n  display: flex;\n  gap: 1rem;\n  width: 500px;\n  transition: all 150ms ease-in-out;\n}\n\n@media screen and (width <= 730px) {\n  .suscription_row {\n    gap: var(--account-layout-gap);\n    flex-direction: column;\n  }\n\n  .suscription_actions__container {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `eiwX7evuNsEnAs9rcPpN`,
	"row": `hMHyJUDrAOmkKSy1izkt`,
	"suscription_actions__container": `p35wmcY6QovLwRMMh1qW`,
	"suscription_row": `tx7QzEamP9DoYU4ezOGg`
};
export default ___CSS_LOADER_EXPORT___;
