// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZpM4JTa23CVD4LDE24kC {
  display: grid;
  gap: 16px;
}

.SsgiD_XgtYWFgubAG9tH {
  display: grid;
  grid-template-columns: 18px auto;
  padding: 10px 14px;
  gap: 10px;
  border: 1px solid #dadada;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #4d4d4d;
}

.SsgiD_XgtYWFgubAG9tH > p {
  text-align: left;
}

.UYyMT1qSbPdyCutUiEh0 {
  display: grid;
  gap: 10px;
}

.SsgiD_XgtYWFgubAG9tH:hover {
  background: #f8f6ff;
  border-color: #937cf4;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManageSubscription/view/ReasonWithdrawal/styles/reasonWithdrawal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,gCAAgC;EAChC,kBAAkB;EAClB,SAAS;EACT,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;AACvB","sourcesContent":[".content {\n  display: grid;\n  gap: 16px;\n}\n\n.reason__label {\n  display: grid;\n  grid-template-columns: 18px auto;\n  padding: 10px 14px;\n  gap: 10px;\n  border: 1px solid #dadada;\n  border-radius: 5px;\n  font-size: 14px;\n  font-weight: 500;\n  color: #4d4d4d;\n}\n\n.reason__label > p {\n  text-align: left;\n}\n\n.reason__content {\n  display: grid;\n  gap: 10px;\n}\n\n.reason__label:hover {\n  background: #f8f6ff;\n  border-color: #937cf4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `ZpM4JTa23CVD4LDE24kC`,
	"reason__label": `SsgiD_XgtYWFgubAG9tH`,
	"reason__content": `UYyMT1qSbPdyCutUiEh0`
};
export default ___CSS_LOADER_EXPORT___;
