import App from "@Pages/App";
import { ReportModalStateProvider } from "@Hooks/ReportModalStateContext";
import "./interceptor";

export default function Root(props) {
  return (
    <ReportModalStateProvider>
      <App />
    </ReportModalStateProvider>
  );
}
