import React from "react";
import style from "./changeModalPlanPrices.module.css";

import { ReportModalStateContextProps } from "@Hooks/ReportModalStateContext";

import SubscriptionPricesModal from "@Components/ui/organisms/SubscriptionPricesModal/SubscriptionPricesModal";
import { SubscriptionPlanCardProps } from "@Components/ui/molecules/SubscriptionPlanCard/SubscriptionPlanCard";
import { ISubscriptionPlan } from "@Models/interfaces/plan";

export const changeModalPlanPrices = (
  modalContext: ReportModalStateContextProps,
  selectedPlanSubscription: ISubscriptionPlan
) => {
  const {
    handleTitleChange,
    handleVisibilityChange,
    handleShowButtonsChange,
    handleButtonTwoChange,
    handleChildrenChange,
    handleActionButtonCloseChange,
    handleNoSizeLimitChange,
  } = modalContext;

  const ModalChildren = (
    <SubscriptionPricesModal plan={selectedPlanSubscription} />
  );

  handleTitleChange(
    <span className={style.header_title_container}>
      Suscripción {selectedPlanSubscription.name}
    </span>
  );
  handleVisibilityChange({
    isVisible: true,
    setIsVisible: () => {},
  });
  handleShowButtonsChange({
    showButtonClose: true,
    showButtonOne: false,
    showButtonTwo: true,
  });
  handleChildrenChange(ModalChildren);
  handleActionButtonCloseChange(() =>
    handleVisibilityChange({
      isVisible: false,
      setIsVisible: () => {},
    })
  );
  handleNoSizeLimitChange(true);
  handleButtonTwoChange({
    textButtonTwo: "Continuar",
    actionButtonTwo: () => {},
    isDisabled: true,
    isLoading: false,
  });
};
