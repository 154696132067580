import { IInvoicingPackage } from "@Models/interfaces/invoicing";
import { getElectronicBillingPackages_service } from "@Services/electronicBilling/getElectronicBillingPackages";

export const getElectronicBillingPackages = async (
  setAvailablePackages: (availablePackages: IInvoicingPackage[]) => void
) => {
  const response = await getElectronicBillingPackages_service();

  if (!response.isSuccess) {
    return response;
  }

  const availableInvoicePackages = response.data.map((plan: any) => {
    return {
      id: plan.packInvoiceId,
      amount: plan.amountInvoiceDocument,
      name: plan.packInvoiceName,
      price: {
        withoutIGV: plan.packInvoiceValue,
        withIGV: plan.packInvoicePrice,
        culqi: plan.culqiPrice,
      },
    };
  });

  setAvailablePackages(availableInvoicePackages);

  return {
    ...response,
    data: availableInvoicePackages,
  };
};
