import CancelSubscriptionConfirmation from "../../view/CancelSubscriptionConfirmation/CancelSubscriptionConfirmation";
import ContinueCancelation from "../../view/ContinueCancelation/ContinueCancelation";
import ReasonWithdrawal from "../../view/ReasonWithdrawal/ReasonWithdrawal";
import DiscountOffer from "../../view/DiscountOffer/DiscountOffer";
import CancelConfirmed from "../../view/CancelConfirmed/CancelConfirmed";

export const CANCELATION_STEPS = {
  CONFIRMATION: {
    title: "¿Estás seguro que deseas cancelar?",
    children: <CancelSubscriptionConfirmation />,
    footerProps: {
      buttonRight: {
        textBttn: "He descargado mi información",
      },
    },
  },
  CONTINUE_CANCELATION: {
    title: "Cancelar suscripción",
    children: <ContinueCancelation />,
    footerProps: {
      buttonLeft: {
        textBttn: "Continuar en Prikly",
        isPrimary: true,
      },
      buttonRight: {
        textBttn: "Cancelar mi plan",
      },
    },
  },
  REASON_WITHDRAWAL: (availableBttn) => ({
    title: "Motivo de baja",
    children: <ReasonWithdrawal availableBttn={() => availableBttn()} />,
    footerProps: {
      buttonRight: {
        textBttn: "Continuar",
        isPrimary: true,
        isDisabled: true,
      },
    },
  }),
  DISCOUNT_PROMOTION: {
    title: "¡Espera! Tenemos una oferta para ti",
    children: <DiscountOffer />,
    footerProps: {
      buttonLeft: {
        textBttn: "Utilizar descuento",
      },
      buttonRight: {
        textBttn: "Confirmar baja",
        isPrimary: true,
      },
    },
  },
  SECURITY_STEP: (approveTheSecurityStep) => ({
    title: "¡Espera! Tenemos una oferta para ti",
    children: (
      <DiscountOffer
        showSecurityStep={true}
        changeStepStatus={approveTheSecurityStep}
      />
    ),
    footerProps: {
      buttonLeft: {
        textBttn: "Utilizar descuento",
      },
      buttonRight: {
        textBttn: "Confirmar baja",
        isPrimary: true,
        isDisabled: true,
      },
    },
  }),
  DELETE_CONFIRMATION: {
    title: "Suscripción cancelada",
    children: <CancelConfirmed />,
    footerProps: {
      buttonRight: {
        textBttn: "Hasta pronto",
        isPrimary: true,
      },
    },
  },
};
