// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FilYuLh9hOhOiNgsVJpq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
  padding: 1rem;
  border-radius: 12px;
  border: 1px solid #e5e5e5;
  background: #ffffff;
  transition: all 150ms ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: #f9f9f9;
  }
}

.d8pDeYcrmOiTpvRzPGmc {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: auto;
  aspect-ratio: 1;
  background-color: #e8e3ff;
  border: 1px solid #d1c6ff;
  border-radius: 50%;
}

.TnKCLD1lmmFRtQkuxy79 {
  color: #45348e;
  font: 600 0.875rem "Mulish", sans-serif;
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/SuscriptionAction/SuscriptionAction.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,QAAQ;EACR,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,iCAAiC;;EAEjC;IACE,eAAe;IACf,yBAAyB;EAC3B;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,yBAAyB;EACzB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,uCAAuC;EACvC,mBAAmB;AACrB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  gap: 8px;\n  padding: 1rem;\n  border-radius: 12px;\n  border: 1px solid #e5e5e5;\n  background: #ffffff;\n  transition: all 150ms ease-in-out;\n\n  &:hover {\n    cursor: pointer;\n    background-color: #f9f9f9;\n  }\n}\n\n.icon {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 32px;\n  height: auto;\n  aspect-ratio: 1;\n  background-color: #e8e3ff;\n  border: 1px solid #d1c6ff;\n  border-radius: 50%;\n}\n\n.label {\n  color: #45348e;\n  font: 600 0.875rem \"Mulish\", sans-serif;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `FilYuLh9hOhOiNgsVJpq`,
	"icon": `d8pDeYcrmOiTpvRzPGmc`,
	"label": `TnKCLD1lmmFRtQkuxy79`
};
export default ___CSS_LOADER_EXPORT___;
