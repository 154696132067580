// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.G433hUqmywY_3IQiiPU0 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 0.75rem 0 0.75rem 1rem;
  border-left: 2px solid #de372f;
}

.hM__Yo7bKCbmvVgwjyIv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.BFHR32rUHIDoDN50kWPG {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #45348e;
  font: 600 1rem "Mulish", sans-serif;
}

.ISnz05iKPQyLQUwSauFN {
  color: #000000;
  font-size: 0.88rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManageSubscription/components/CurrentSubscriptionBanner/CurrentSubscriptionBanner.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,uBAAuB;EACvB,SAAS;EACT,WAAW;EACX,+BAA+B;EAC/B,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,cAAc;EACd,mCAAmC;AACrC;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB","sourcesContent":[".payment_details__container {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  gap: 10px;\n  width: 100%;\n  padding: 0.75rem 0 0.75rem 1rem;\n  border-left: 2px solid #de372f;\n}\n\n.payment_period__container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n}\n\n.payment_period {\n  display: flex;\n  align-items: center;\n  gap: 6px;\n  color: #45348e;\n  font: 600 1rem \"Mulish\", sans-serif;\n}\n\n.payment_details__message {\n  color: #000000;\n  font-size: 0.88rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payment_details__container": `G433hUqmywY_3IQiiPU0`,
	"payment_period__container": `hM__Yo7bKCbmvVgwjyIv`,
	"payment_period": `BFHR32rUHIDoDN50kWPG`,
	"payment_details__message": `ISnz05iKPQyLQUwSauFN`
};
export default ___CSS_LOADER_EXPORT___;
