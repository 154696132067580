export const mockProfileData = {
  firstName: "Florencia",
  lastName: "Perotti",
  document: "12345678",
  email: "florencia.perotti@lecoiffeur.pe",
  birthDate: "1992-07-13",
  gender: "female",
  phone: "+51 10-424-7284",
  country: "Perú",
};
