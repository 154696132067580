// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dqee9X7MUuzcb9tD8iEX {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-bottom: 20px;
}

.T00PfFJ0WIeyPPK7olnS {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  min-height: 250px;
}

.GKwhzmfDsXN_7V0l2zMt {
  font: 500 0.9rem "Mulish", sans-serif;
}

.c3BX85h19h1siHFkzOkh {
  display: flex;
  align-items: center;
  justify-content: center;
}

.VG0fW2UucVS3XLyTDHjp {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 300ms ease;
}

.FNQKEejbJ5s7lTCbHouQ {
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  font-size: var(--font-size-h6);
  color: #6750a3;
  text-align: center;
}

.mycPIW7B122yPwz856Oc {
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-size: var(--font-size-reading);
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/PaymentResponse/PaymentResponse.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,8BAA8B;EAC9B,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,mCAAmC;EACnC,kBAAkB;AACpB","sourcesContent":[".payment__container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 1rem;\n  padding-bottom: 20px;\n}\n\n.payment_loading__container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 2rem;\n  min-height: 250px;\n}\n\n.payment_loading__message {\n  font: 500 0.9rem \"Mulish\", sans-serif;\n}\n\n.payment__icon__container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.payment__icon {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n  transition: all 300ms ease;\n}\n\n.payment__title {\n  font-family: \"Mulish\", sans-serif;\n  font-weight: 700;\n  font-size: var(--font-size-h6);\n  color: #6750a3;\n  text-align: center;\n}\n\n.payment__message {\n  font-family: \"Mulish\", sans-serif;\n  font-weight: 400;\n  font-size: var(--font-size-reading);\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payment__container": `Dqee9X7MUuzcb9tD8iEX`,
	"payment_loading__container": `T00PfFJ0WIeyPPK7olnS`,
	"payment_loading__message": `GKwhzmfDsXN_7V0l2zMt`,
	"payment__icon__container": `c3BX85h19h1siHFkzOkh`,
	"payment__icon": `VG0fW2UucVS3XLyTDHjp`,
	"payment__title": `FNQKEejbJ5s7lTCbHouQ`,
	"payment__message": `mycPIW7B122yPwz856Oc`
};
export default ___CSS_LOADER_EXPORT___;
