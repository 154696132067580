import { accountBaseUrl, getData } from "@Services/constServices";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const getBillingData_service = async () => {
  const url = `${accountBaseUrl}/BillingInformation/GetBillingInformation`;
  const ingresa_vista_administracion_de_facturacion = 51;
  trackUserAction(ingresa_vista_administracion_de_facturacion);
  const successMessage = "Datos de facturación obtenidos";
  const errorMessage = "Datos de facturación no obtenidos";
  return getData(url, successMessage, errorMessage);
};
