import React from "react";
import style from "./HomePage.module.css";
// @ts-ignore
import { HeaderNavigation, TabNavigation } from "@viuti/recursos";
import PayHistory from "@Pages/views/PayHistory/PayHistory";
import AdminAccount from "@Pages/views/AdminAccount/AdminAccount";
import { LoadingPulseIcon } from "@Models/icons";

interface IHomePage {}

const HomePage = () => {
  const tabNavProps = {
    views: [
      {
        name: "Administrar cuenta",
        component: <AdminAccount />,
      },
      // {
      //   name: "Historial de pagos",
      //   component: <PayHistory />,
      // },
    ],
  };

  const temp = false;

  return (
    <>
      {/* {loungesFetchResponse.status === 'loading' ? ( */}
      {temp ? (
        <div className={style.loader_container}>
          <img
            className={style.loader_icon}
            src={LoadingPulseIcon}
            alt="AnimatedLoader"
          />
        </div>
      ) : (
        <div className={style.view__container}>
          <HeaderNavigation
            title={"Mi cuenta"}
            previousAction={false}
            previousActionMovement={"close"}
            buttonProps={{
              textBttn: "",
              handleClick: () => {},
              isDisabled: true,
              isPrimary: true,
              isHidden: true,
            }}
          />
          <TabNavigation {...tabNavProps} />
        </div>
      )}
    </>
  );
};

export default HomePage;
