import { create } from "zustand";
import {
  ICurrentPlan,
  ICurrentSubscription,
  IPlanFeature,
  ISubscriptionPlan,
} from "@Models/interfaces/plan";

interface ICurrentSubscriptionPlanStore extends ICurrentPlan {
  setId: (id: number) => void;
  setName: (name: string) => void;
  setCategory: (category: string) => void;
  setUsers: (users: { quantity: number; unlimited: boolean }) => void;
  setFeatures: (features: IPlanFeature[]) => void;
  setIsFreeTrial: (isFreeTrial: boolean) => void;
  setSubscription: (subscription: ICurrentSubscription) => void;
}

export interface IPlanStore {
  currentPlan: ICurrentSubscriptionPlanStore;
  currentPlanFetchResponse: IFetchReportResponse;
  setCurrentPlanFetchResponse: (response: IFetchReportResponse) => void;

  availablePlans: ISubscriptionPlan[];
  setAvailablePlans: (availablePlans: ISubscriptionPlan[]) => void;
  availablePlansFetchResponse: IFetchReportResponse;
  setAvailablePlansFetchResponse: (response: IFetchReportResponse) => void;
}

export const usePlanStore = create((set) => ({
  currentPlan: {
    id: 0,
    name: "",
    category: "",
    users: {
      quantity: 0,
      unlimited: false,
    },
    features: [],
    isFreeTrial: false,
    subscription: {
      isActive: false,
      startDate: "",
      endDate: "",
    },
  },
  currentPlanFetchResponse: {
    status: "loading",
    message: "",
  },
  setCurrentPlanFetchResponse: (response: IFetchReportResponse) =>
    set({ currentPlanFetchResponse: response }),

  availablePlans: null,
  setAvailablePlans: (availablePlans: ISubscriptionPlan[]) =>
    set({ availablePlans }),

  availablePlansFetchResponse: {
    status: "loading",
    message: "",
  },
  setAvailablePlansFetchResponse: (response: IFetchReportResponse) =>
    set({ availablePlansFetchResponse: response }),
}));

export const updateCurrentPlanStore = (currentPlanStore: ICurrentPlan) => {
  usePlanStore.setState({ currentPlan: currentPlanStore });
};
