import { useContext, useEffect, useState } from "react";
import { IPlanStore, usePlanStore } from "@Stores/usePlanStore";
import { getSubscriptionPlans } from "@Adapters/suscriptions/getSubscriptionPlans.adapter";
import { SubscriptionPlanCardProps } from "@Components/ui/molecules/SubscriptionPlanCard/SubscriptionPlanCard";
import { changeModalPlanPrices } from "@Hooks/changeModalPlanPrices/changeModalPlanPrices";
import {
  ReportModalStateContext,
  ReportModalStateContextProps,
} from "@Hooks/ReportModalStateContext";
import { ISubscriptionPlan } from "@Models/interfaces/plan";
import { getCurrentPlan_adapter } from "@Adapters/suscriptions/getCurrentPlan.adapter";

const useAvailablePlans = () => {
  const modalContext = useContext(
    ReportModalStateContext
  ) as ReportModalStateContextProps;
  const {
    currentPlanFetchResponse,
    setCurrentPlanFetchResponse,
    availablePlans,
    setAvailablePlans,
    availablePlansFetchResponse,
    setAvailablePlansFetchResponse,
  } = usePlanStore() as IPlanStore;

  const [renderPlans, setRenderPlans] = useState<SubscriptionPlanCardProps[]>(
    []
  );

  const handleSendMessage = () => {
    const message = `¡Hola estoy interesado en suscribirme al plan pro de Prikly! ¿Puedes ayudarme?`;
    window.open(
      `https://wa.me/+51905453587?text=${encodeURIComponent(message)}`
    );
  };

  const formatPlans = (rawPlans: ISubscriptionPlan[]) => {
    const plans = rawPlans.map((plan) => {
      const basicFooter = {
        type: "button",
        content: plan.isCurrentPlan
          ? "Actualizar suscripción"
          : "Obtener Plan Emprendedor",
        onClick: () => changeModalPlanPrices(modalContext, plan),
      };
      const suggestedFooter = {
        type: "button",
        content: plan.isCurrentPlan
          ? "Actualizar suscripción"
          : "Obtener Plan Estándar",
        onClick: () => changeModalPlanPrices(modalContext, plan),
      };
      const proFooter = {
        type: "button",
        // content: plan.isCurrentPlan
        //   ? "Actualizar suscripción"
        //   : "Obtener Plan Pro",

        content: "Contactáctanos",
        disabled: false,
        // onClick: () => changeModalPlanPrices(modalContext, plan),
        onClick: () => handleSendMessage(),
      };
      return {
        ...plan,
        footer:
          plan.id === 2
            ? basicFooter
            : plan.id === 3
            ? suggestedFooter
            : proFooter,
      };
    }) as SubscriptionPlanCardProps[];

    setRenderPlans(plans);
  };

  useEffect(() => {
    const fetchPlans = async () => {
      if (availablePlansFetchResponse.status !== "success") {
        setAvailablePlansFetchResponse({
          status: "loading",
          message: "",
        });
        const response = await getSubscriptionPlans({ setAvailablePlans });
        if (response.isSuccess) {
          // @ts-ignore
          formatPlans(response.data);
        }
        setAvailablePlansFetchResponse({
          status: response.isSuccess ? "success" : "error",
          message: response.message,
        });
      } else if (availablePlansFetchResponse.status === "success") {
        formatPlans(availablePlans);
      }
    };

    fetchPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (currentPlanFetchResponse.status !== "success") {
        setCurrentPlanFetchResponse({
          status: "loading",
          message: "",
        });
        const response = await getCurrentPlan_adapter();
        setCurrentPlanFetchResponse({
          status: response.isSuccess ? "success" : "error",
          message: response.message,
        });
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { renderPlans, availablePlansFetchResponse };
};

export default useAvailablePlans;
