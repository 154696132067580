import { IModalWrapper } from "@Components/wrapper/ModalWrapper/ModalWrapper";

interface IButtonUpdateOptions {
  handler?: () => void; // Función opcional para manejar el clic del botón
  isDisabled?: boolean; // Indica si el botón está deshabilitado
  isLoading?: boolean;
}

interface IButtonsUpdateOptions {
  buttonLeft?: IButtonUpdateOptions; // Opciones de actualización para el botón izquierdo
  buttonRight?: IButtonUpdateOptions; // Opciones de actualización para el botón derecho
}

/**
 * Actualiza la configuración de los botones en un paso de modal.
 * @param step - El objeto del paso del modal a actualizar.
 * @param updates - Opciones de actualización para los botones izquierdo y derecho.
 * @returns El paso actualizado con las nuevas configuraciones de los botones.
 */
export const updateButtonSettings = (
  step: IModalWrapper,
  updates: IButtonsUpdateOptions
): IModalWrapper => {
  const { buttonLeft, buttonRight } = updates;

  return {
    ...step,
    footerProps: {
      ...step.footerProps,
      // Actualiza el botón izquierdo si se proporcionan actualizaciones
      ...(buttonLeft && {
        buttonLeft: {
          ...step.footerProps.buttonLeft,
          ...(buttonLeft.handler !== undefined && {
            handleClick: buttonLeft.handler, // Actualiza el manejador de clic si está definido
          }),
          ...(buttonLeft.isDisabled !== undefined && {
            isDisabled: buttonLeft.isDisabled, // Actualiza el estado de deshabilitado si está definido
          }),
          ...(buttonLeft.isLoading !== undefined && {
            isLoading: buttonLeft.isLoading,
          }),
        },
      }),
      // Actualiza el botón derecho si se proporcionan actualizaciones
      ...(buttonRight && {
        buttonRight: {
          ...step.footerProps.buttonRight,
          ...(buttonRight.handler !== undefined && {
            handleClick: buttonRight.handler, // Actualiza el manejador de clic si está definido
          }),
          ...(buttonRight.isDisabled !== undefined && {
            isDisabled: buttonRight.isDisabled, // Actualiza el estado de deshabilitado si está definido
          }),
          ...(buttonRight.isLoading !== undefined && {
            isLoading: buttonRight.isLoading,
          }),
        },
      }),
    },
  };
};
