// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v6WJ2R9xzOTMODws1zFk {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 24px;
  overflow-y: auto;
}

.zIkda_sTz58ES3mvv1Jn {
  display: flex;
  gap: 1rem;
}

@media screen and (width <= 720px) {
  .O9Q1_hJLZ_O0KclvhapY {
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManageSubscription/ManageSubscription.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".view__container {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  gap: 24px;\n  overflow-y: auto;\n}\n\n.row {\n  display: flex;\n  gap: 1rem;\n}\n\n@media screen and (width <= 720px) {\n  .plan_row {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"view__container": `v6WJ2R9xzOTMODws1zFk`,
	"row": `zIkda_sTz58ES3mvv1Jn`,
	"plan_row": `O9Q1_hJLZ_O0KclvhapY`
};
export default ___CSS_LOADER_EXPORT___;
