import { useEffect, useState } from "react";
import {
  Form,
  SearchReniecProps,
} from "../interfaces/IModalPlanEdit.interface";
import { getSearchReniec_adapter } from "@Adapters/getSearchReniec.adapter";

export const useSearchReniec = ({ form, setForm }: SearchReniecProps) => {
  const [status, setStatus] = useState({
    error: false,
    validated: false,
    loading: false,
    message: "",
  });

  useEffect(() => {
    (async () => {
      if (form?.identityNumber?.length === 11) {
        setStatus((prevStatus) => ({
          ...prevStatus,
          loading: true,
          message: "Buscando información, aguarde un momento...",
          error: false,
        }));
        const response = await getSearchReniec_adapter(form.identityNumber);
        if (response.isSuccess) {
          setForm((prevForm: Form) => ({
            ...prevForm,
            reasonSocial: response.data.reasonSocial,
            address: response.data.address,
          }));
        }
        setStatus((prevStatus) => ({
          ...prevStatus,
          loading: false,
          message: "",
        }));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.identityNumber]);

  return { status };
};
