import { accountBaseUrl, getData } from "@Services/constServices";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const getElectronicBillingPackages_service = async () => {
  const url = `${accountBaseUrl}/Vouchers/GetPacksDocumentInvoice`;
  const ingresa_vista_mi_cuenta = 50;
  trackUserAction(ingresa_vista_mi_cuenta);
  const successMessage = "Paquetes obtenido correctamente";
  const errorMessage = "No se pudo obtener los paquetes de compra electrónica";
  return getData(url, successMessage, errorMessage);
};
