import React from "react";
import style from "./CurrentPlanBanner.module.css";
import PaymentMessage from "./components/PaymentMessage/PaymentMessage";

import { ROUTES } from "@Models/routes";
import { IPlanStore, usePlanStore } from "@Stores/usePlanStore";
// @ts-ignore
import { DualBars, getUserPermissions } from "@viuti/recursos";
import { useNavigate } from "react-router-dom";

const CurrentPlanBanner = () => {
  const navigate = useNavigate();
  const { currentPlan, currentPlanFetchResponse } =
    usePlanStore() as IPlanStore;

  const isTrialSubscription = currentPlan.isFreeTrial;

  return (
    <button
      className={style.container}
      onClick={() => navigate(ROUTES.MANAGE_SUBSCRIPTION_ROUTE)}
    >
      {currentPlanFetchResponse.status === "loading" ? (
        <DualBars />
      ) : currentPlanFetchResponse.status === "success" ? (
        <SuccessBanner
          currentPlan={currentPlan}
          isTrialSubscription={isTrialSubscription}
        />
      ) : (
        <FailedBanner />
      )}
    </button>
  );
};

const SuccessBanner = ({ currentPlan, isTrialSubscription }) => {
  return (
    <>
      <span className={style.header}>
        <p className={style.suscription}>Tu suscripción</p>
        <div className={style.plan_details}>
          <h3 className={style.plan_name}>{currentPlan.name}</h3>
          {isTrialSubscription && (
            <span className={style.plan_chip}>Plan gratuito</span>
          )}
        </div>
      </span>
      <PaymentMessage isTrialSubscription={isTrialSubscription} />
    </>
  );
};

const FailedBanner = () => {
  return (
    <>
      <span className={style.header}>
        <div className={style.plan_details}>
          <h3 className={`${style.plan_name} ${style.plan_name__error}`}>
            ¡Ups! Error inesperado.
          </h3>
        </div>
      </span>
      <p className={style.message}>
        No se pudo cargar la información de tu suscripción, por favor, intenta
        de nuevo.
      </p>
    </>
  );
};

export default CurrentPlanBanner;
