// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ssM2gIWyQV4bMmcTJKBY {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.Vdq2wxe1LTvbh8e0xcY0,
.jItNTe_IMUPcyKKYcGaw {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: all 150ms ease-in-out;
  padding: 16px;
  border: 1px solid #0000001a;
  border-radius: 6px;
  background-color: #f9f8fe;

  @media screen and (width <= 550px) {
    gap: 16px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.Vdq2wxe1LTvbh8e0xcY0 {
  &:hover {
    background: #f8f6ff;
    border-color: #937cf4;
  }
}

.jItNTe_IMUPcyKKYcGaw {
  background: #eeeaff;
  border-color: #937cf4;

  &:hover {
    background: #f8f6ff;
    border-color: #937cf4;
  }
}

.IIbxFQqixdl4yO3S7Y2X {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.A_zoDTURqVYDmhNfUngX {
  font-family: "Mulish", sans-serif;
  font-weight: 500;
}

.DN_9k_jdNYEFTUt6DXKX {
  color: #392b75;
  font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/PackageList/PackageList.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;AACX;;AAEA;;EAEE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;EACX,iCAAiC;EACjC,aAAa;EACb,2BAA2B;EAC3B,kBAAkB;EAClB,yBAAyB;;EAEzB;IACE,SAAS;IACT,sBAAsB;IACtB,uBAAuB;EACzB;AACF;;AAEA;EACE;IACE,mBAAmB;IACnB,qBAAqB;EACvB;AACF;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;;EAErB;IACE,mBAAmB;IACnB,qBAAqB;EACvB;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  gap: 15px;\n}\n\n.card,\n.selected {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n  transition: all 150ms ease-in-out;\n  padding: 16px;\n  border: 1px solid #0000001a;\n  border-radius: 6px;\n  background-color: #f9f8fe;\n\n  @media screen and (width <= 550px) {\n    gap: 16px;\n    flex-direction: column;\n    align-items: flex-start;\n  }\n}\n\n.card {\n  &:hover {\n    background: #f8f6ff;\n    border-color: #937cf4;\n  }\n}\n\n.selected {\n  background: #eeeaff;\n  border-color: #937cf4;\n\n  &:hover {\n    background: #f8f6ff;\n    border-color: #937cf4;\n  }\n}\n\n.containerContent {\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n}\n\n.total {\n  font-family: \"Mulish\", sans-serif;\n  font-weight: 500;\n}\n\n.amount {\n  color: #392b75;\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ssM2gIWyQV4bMmcTJKBY`,
	"card": `Vdq2wxe1LTvbh8e0xcY0`,
	"selected": `jItNTe_IMUPcyKKYcGaw`,
	"containerContent": `IIbxFQqixdl4yO3S7Y2X`,
	"total": `A_zoDTURqVYDmhNfUngX`,
	"amount": `DN_9k_jdNYEFTUt6DXKX`
};
export default ___CSS_LOADER_EXPORT___;
