import { useContext } from "react";
import { ROUTES } from "@Models/routes";
import { Route, Routes } from "react-router-dom";
import {
  NotFoundPage,
  ModalAcceptCancel,
  SlideNotification,
  ProtectedRoute,
  // @ts-ignore
} from "@viuti/recursos";
import { IToasterStore, useToasterStore } from "../store/useToasterStore";
import {
  ReportModalStateContext,
  ReportModalStateContextProps,
} from "@Hooks/ReportModalStateContext";
import HomePage from "@Pages/Home/HomePage";
import ManageSubscription from "@Pages/ManageSubscription/ManageSubscription";
import EditProfile from "@Pages/EditProfile/EditProfile";
import AvailablePlans from "@Pages/AvailablePlans/AvailablePlans";
import UpdatePlan from "@Pages/UpdatePlan/UpdatePlan";
import ManagePaymentMethods from "@Pages/ManagePaymentMethods/ManagePaymentMethods";
import { UPDATE_SUBSCRIPTION_UUID } from "@Models/const/securityAccess";

const HomeRoute = () => {
  const modalContext = useContext(
    ReportModalStateContext
  ) as ReportModalStateContextProps;
  // Slide Notification
  const { message, status, loading, setEmpty } =
    useToasterStore() as IToasterStore;

  return (
    <>
      <div id="viuti-front-mainContent">
        <Routes>
          <Route path={ROUTES.HOME_ROUTE} element={<HomePage />} />
          <Route
            path={ROUTES.MANAGE_SUBSCRIPTION_ROUTE}
            element={<ManageSubscription />}
          />
          <Route path={ROUTES.EDIT_PROFILE_ROUTE} element={<EditProfile />} />
          <Route
            path={ROUTES.AVAILABLE_PLANS_ROUTE}
            element={
              <ProtectedRoute
                accessKeyModule={UPDATE_SUBSCRIPTION_UUID}
                element={<AvailablePlans />}
              />
            }
          />
          <Route
            path={ROUTES.UPDATE_PLAN_ROUTE}
            element={
              <ProtectedRoute
                accessKeyModule={UPDATE_SUBSCRIPTION_UUID}
                element={<UpdatePlan />}
              />
            }
          />
          {/* <Route
            path={ROUTES.MANAGE_PAYMENT_METHODS_ROUTE}
            element={<ManagePaymentMethods />}
          /> */}
          <Route path="*" element={<NotFoundPage />}></Route>
        </Routes>
      </div>
      <ModalAcceptCancel {...modalContext}>
        {modalContext.modalChildren}
      </ModalAcceptCancel>
      <SlideNotification
        state={{
          message: message,
          status: status,
          loading: loading,
        }}
        clearStatus={() => setEmpty()}
      />
    </>
  );
};

export default HomeRoute;
