export type IReasonSelected =
  | "EXPENSIVE"
  | "FREQUENCY"
  | "FIND AN ALTERNATIVE"
  | "CUSTOMER SUPPORT"
  | "FUNCTIONALITY";

export const REASONS_WITHDRAWAL: Array<{
  id: string;
  value: IReasonSelected;
  label: string;
}> = [
  {
    id: "expensive",
    value: "EXPENSIVE",
    label: "El servicio es demasiado caro para mi presupuesto actual.",
  },
  {
    id: "frequency",
    value: "FREQUENCY",
    label:
      "No estoy utilizando el servicio con suficiente frecuencia para justificar la suscripción.",
  },
  {
    id: "alternative",
    value: "FIND AN ALTERNATIVE",
    label:
      "He encontrado una alternativa que se adapta mejor a mis necesidades.",
  },
  {
    id: "support",
    value: "CUSTOMER SUPPORT",
    label:
      "La calidad del servicio o soporte al cliente no cumple con mis expectativas.",
  },
  {
    id: "functionality",
    value: "FUNCTIONALITY",
    label: "Ya no necesito las funcionalidades que ofrece el servicio.",
  },
];
