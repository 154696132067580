import { Form, ILocation } from "../interfaces/IModalPlanEdit.interface";

export const handleChangeType = (
  e: React.ChangeEvent<HTMLSelectElement>,
  setForm: React.Dispatch<React.SetStateAction<Form>>
) => {
  const { value } = e.target;
  setForm((prevForm) => ({
    ...prevForm,
    typeId: Number(value),
    identityNumber: "",
    identityName: "",
  }));
};

export const handleChangeDNINumber = (
  e: React.ChangeEvent<HTMLInputElement>,
  setForm: React.Dispatch<React.SetStateAction<Form>>
) => {
  const { value } = e.target;
  let valueNumberFormatted = value.replace(/[^0-9]/g, "");
  if (value.length > 8) {
    return;
  }
  setForm((prevForm) => ({
    ...prevForm,
    identityNumber: valueNumberFormatted,
  }));
};

export const handleChangeRUCNumber = (
  e: React.ChangeEvent<HTMLInputElement>,
  setForm: React.Dispatch<React.SetStateAction<Form>>
) => {
  const { value } = e.target;
  let valueNumberFormatted = value.replace(/[^0-9]/g, "");
  if (value.length > 11) {
    return;
  }
  setForm((prevForm) => ({
    ...prevForm,
    identityNumber: valueNumberFormatted,
  }));
};

export const handleChangeName = (
  e: React.ChangeEvent<HTMLInputElement>,
  setForm: React.Dispatch<React.SetStateAction<Form>>
) => {
  const { value } = e.target;

  const sanitizedValue = value.replace(/[^a-zA-ZñÑ\s]/g, "");

  const capitalizeWords = (str: string) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const formattedValue = capitalizeWords(sanitizedValue);

  setForm((prevForm) => ({
    ...prevForm,
    identityName: formattedValue,
  }));
};

export const handleChangeReasonSocial = (
  e: React.ChangeEvent<HTMLInputElement>,
  setForm: React.Dispatch<React.SetStateAction<Form>>
) => {
  const { value } = e.target;
  setForm((prevForm) => ({
    ...prevForm,
    identityName: value,
  }));
};

export const handleChangeEmail = (
  e: React.ChangeEvent<HTMLInputElement>,
  setForm: React.Dispatch<React.SetStateAction<Form>>
) => {
  const { value } = e.target;
  setForm((prevForm) => ({
    ...prevForm,
    email: value,
  }));
};

export const handleChangeDepartment = (
  e: React.ChangeEvent<HTMLSelectElement>,
  setForm: React.Dispatch<React.SetStateAction<Form>>,
  setProvinces: React.Dispatch<React.SetStateAction<ILocation[]>>
) => {
  const { value } = e.target;
  // @ts-ignore
  setForm((prevForm) => ({
    ...prevForm,
    departmentId: Number(value),
    provinceId: "",
    districtId: "",
  }));
  setProvinces([]);
};

export const handleChangeProvince = (
  e: React.ChangeEvent<HTMLSelectElement>,
  setForm: React.Dispatch<React.SetStateAction<Form>>,
  setDistricts: React.Dispatch<React.SetStateAction<ILocation[]>>
) => {
  const { value } = e.target;
  // @ts-ignore
  setForm((prevForm) => ({
    ...prevForm,
    provinceId: Number(value),
    districtId: "",
  }));
  setDistricts([]);
};

export const handleChangeDistrict = (
  e: React.ChangeEvent<HTMLSelectElement>,
  setForm: React.Dispatch<React.SetStateAction<Form>>
) => {
  const { value } = e.target;
  setForm((prevForm) => ({
    ...prevForm,
    districtId: Number(value),
  }));
};

export const handleChangeAddress = (
  e: React.ChangeEvent<HTMLInputElement>,
  setForm: React.Dispatch<React.SetStateAction<Form>>
) => {
  const { value } = e.target;
  setForm((prevForm) => ({
    ...prevForm,
    address: value,
  }));
};
