import React from "react";
import style from "./SuscriptionAction.module.css";

interface ISuscriptionActionProps {
  icon: JSX.Element;
  label: string;
  onClick: () => void;
}
const SuscriptionAction: React.FC<ISuscriptionActionProps> = ({
  icon,
  label,
  onClick,
}) => {
  return (
    <button className={style.container} onClick={onClick}>
      <span className={style.icon}>{icon}</span>
      <p className={style.label}>{label}</p>
    </button>
  );
};

export default SuscriptionAction;
