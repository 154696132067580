import React, { useState, useCallback } from "react";
import styles from "./styles/reasonWithdrawal.module.css";
// @ts-ignore
import { CircularRadioInput } from "@viuti/recursos";
import { IReasonSelected, REASONS_WITHDRAWAL } from "./reasons.list";

interface ReasonButtonProps {
  id: string;
  reason: IReasonSelected;
  isChecked: boolean;
  onClick: () => void;
  label: string;
}

const ReasonButton: React.FC<ReasonButtonProps> = ({
  id,
  isChecked,
  onClick,
  label,
}) => (
  <button className={styles.reason__label} onClick={onClick}>
    <CircularRadioInput id={id} checked={isChecked} onClick={onClick} />
    <p>{label}</p>
  </button>
);

const ReasonWithdrawal = ({ availableBttn }) => {
  const [reasonSelected, setReasonSelected] = useState<IReasonSelected>();

  const handleSelectReason = useCallback((reason: IReasonSelected) => {
    availableBttn();
    setReasonSelected(reason);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={styles.content}>
      <p>
        Por favor, selecciona el motivo principal por el que deseas dar de baja
        tu suscripción.
      </p>
      <article className={styles.reason__content}>
        {REASONS_WITHDRAWAL.map((reason) => (
          <ReasonButton
            key={reason.id}
            id={reason.id}
            reason={reason.value}
            isChecked={reasonSelected === reason.value}
            onClick={() => handleSelectReason(reason.value)}
            label={reason.label}
          />
        ))}
      </article>
    </section>
  );
};

export default ReasonWithdrawal;
