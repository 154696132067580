// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zgQzbx4g9xTBrAcL6qDm {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 6px;
}

.THaYjemcBpZca4tKsESW {
  text-align: right;
  color: #000000;
  font: 600 0.875rem "Mulish", sans-serif;
}

.xw34syYAQYfz9EL4y5ZK {
  text-align: right;
  color: #000000;
  font: 600 0.8rem "Mulish", sans-serif;

  a {
    color: #5d36f1;
    font-weight: 700;
    white-space: nowrap;
    font-size: 0.8rem;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media screen and (width <= 1064px) {
  .zgQzbx4g9xTBrAcL6qDm {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .THaYjemcBpZca4tKsESW,
  .xw34syYAQYfz9EL4y5ZK {
    text-align: left;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/CurrentPlanBanner/components/PaymentMessage/PaymentMessage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,qBAAqB;EACrB,QAAQ;AACV;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,uCAAuC;AACzC;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,qCAAqC;;EAErC;IACE,cAAc;IACd,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;;IAEjB;MACE,0BAA0B;IAC5B;EACF;AACF;;AAEA;EACE;IACE,WAAW;IACX,2BAA2B;IAC3B,uBAAuB;EACzB;;EAEA;;IAEE,gBAAgB;EAClB;AACF","sourcesContent":[".payment {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  align-items: flex-end;\n  gap: 6px;\n}\n\n.remain_payment__message {\n  text-align: right;\n  color: #000000;\n  font: 600 0.875rem \"Mulish\", sans-serif;\n}\n\n.details_payment__message {\n  text-align: right;\n  color: #000000;\n  font: 600 0.8rem \"Mulish\", sans-serif;\n\n  a {\n    color: #5d36f1;\n    font-weight: 700;\n    white-space: nowrap;\n    font-size: 0.8rem;\n\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n}\n\n@media screen and (width <= 1064px) {\n  .payment {\n    width: 100%;\n    justify-content: flex-start;\n    align-items: flex-start;\n  }\n\n  .remain_payment__message,\n  .details_payment__message {\n    text-align: left;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payment": `zgQzbx4g9xTBrAcL6qDm`,
	"remain_payment__message": `THaYjemcBpZca4tKsESW`,
	"details_payment__message": `xw34syYAQYfz9EL4y5ZK`
};
export default ___CSS_LOADER_EXPORT___;
