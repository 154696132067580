import React from "react";
import style from "./Profile.module.css";
// @ts-ignore
import { Avatar, ButtonWithoutIcon } from "@viuti/recursos";
import { ROUTES } from "@Models/routes";
import { useNavigate } from "react-router-dom";

interface ProfileProps {
  name: string;
  email: string;
  phone: string;
  role: string;
  company: string;
}

const Profile: React.FC<ProfileProps> = ({
  name,
  email,
  phone,
  role,
  company,
}) => {
  const navigate = useNavigate();

  return (
    <article className={style.container}>
      <div className={style.content}>
        <Avatar name={name} editImagen={false} />
        <div className={style.info__container}>
          <h2>Perfil</h2>
          <div className={style.info}>
            <h3>{name}</h3>
            <p>{email}</p>
            <p>{phone}</p>
            <p>
              <span className={style.role}>{role}</span> |{" "}
              <span className={style.company}>{company}</span>
            </p>
          </div>
        </div>
      </div>
      {/* <ButtonWithoutIcon
        textBttn="Editar perfil"
        isPrimary
        handleClick={() => navigate(ROUTES.EDIT_PROFILE_ROUTE)}
        width={150}
        style={{
          height: 40,
        }}
      /> */}
    </article>
  );
};

export default Profile;
