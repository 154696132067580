import React, { useContext } from "react";
import style from "./BillingDocumentsCard.module.css";
import CircularProgress from "@Components/ui/atoms/CircularProgress/CircularProgress";
import {
	ButtonWithoutIcon,
	NoInfoToShow,
	LoadingScreen,
	validateModuleAccess,
	// @ts-ignore
} from "@viuti/recursos";
import { changeModalBuyInvoicing } from "@Hooks/changeModalBuyInvoicing/changeModalBuyInvoicing";
import {
	ReportModalStateContext,
	ReportModalStateContextProps,
} from "@Hooks/ReportModalStateContext";
import {
	IInvoicingPackagesStore,
	useInvoicingPackagesStore,
} from "@Stores/useInvoicingPackagesStore";
import { OpenBoxIcon } from "@Models/icons";
import { HiDocumentText } from "react-icons/hi2";
import { BUY_BILLING_DOCUMENTS_UUID } from "@Models/const/securityAccess";

const BillingDocumentsCard = () => {
	const modalContext = useContext(
		ReportModalStateContext
	) as ReportModalStateContextProps;
	const { total, used, stockFetchResponse, availablePackagesFetchResponse } =
		useInvoicingPackagesStore() as IInvoicingPackagesStore;

	return (
		<article className={style.container}>
			<div className={style.content}>
				<h2 className={style.title}>Consumo de facturación</h2>
				{stockFetchResponse.status === "success" &&
					availablePackagesFetchResponse.status === "success" && (
						<>
							<CircularProgress
								documentsUsed={used}
								accountDocuments={total}
								width="200px"
							/>
							<ButtonWithoutIcon
								isPrimary
								textBttn={"Comprar más comprobantes"}
								handleClick={() =>
									validateModuleAccess(BUY_BILLING_DOCUMENTS_UUID) &&
									changeModalBuyInvoicing(modalContext)
								}
								width="100%"
							/>
						</>
					)}
				{availablePackagesFetchResponse.status === "loading" ? (
					<LoadingScreen visible={true} loaderWidth={150} />
				) : (
					availablePackagesFetchResponse.status === "error" && (
						<NoInfoToShow
							title="¡Ocurrió un error!"
							message={availablePackagesFetchResponse.message}
							icon={OpenBoxIcon}
							error={true}
						>
							<HiDocumentText size={32} color="#b91616" />
						</NoInfoToShow>
					)
				)}
			</div>
		</article>
	);
};

export default BillingDocumentsCard;
