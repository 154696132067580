import { create } from "zustand";

export interface IToasterStore {
  message: string;
  setMessage: (message: string) => void;
  status: number;
  setStatus: (status: number) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setToaster: (message: string, status: number, loading: boolean) => void;
  setEmpty: () => void;
}

export const useToasterStore = create((set) => ({
  message: "",
  setMessage: (message: string) => set({ message }),
  status: 0,
  setStatus: (status: number) => set({ status }),
  loading: false,
  setLoading: (loading: boolean) => set({ loading }),
  setToaster: (message: string, status: number, loading: boolean) => {
    set({ message, status, loading });
  },
  setEmpty: () => set({ message: "", status: 0, loading: false }),
}));
