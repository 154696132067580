// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AnGPu6QUVLmJGHbZZj6v {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: auto;
  gap: 1rem;
  border-radius: 12px;
  border: 1px solid #e5e5e5;
  background: #ffffff;
  transition: all 150ms ease-in-out;
}

@media screen and (width <= 730px) {
  .AnGPu6QUVLmJGHbZZj6v {
    width: 100%;
  }
}

.EaA9Z5EO8B4wEojoM12l {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 14px - 14px);
  height: 100%;
  gap: 1rem;
  padding: 14px 0;
}

.rN5yFwM2hmZYz8aEMcoY {
  width: 100%;
  color: #45348e;
  font: 700 1.2rem "Mulish", sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/BillingDocumentsCard/BillingDocumentsCard.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,SAAS;EACT,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,iCAAiC;AACnC;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,+BAA+B;EAC/B,YAAY;EACZ,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,qCAAqC;AACvC","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 500px;\n  height: auto;\n  gap: 1rem;\n  border-radius: 12px;\n  border: 1px solid #e5e5e5;\n  background: #ffffff;\n  transition: all 150ms ease-in-out;\n}\n\n@media screen and (width <= 730px) {\n  .container {\n    width: 100%;\n  }\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: calc(100% - 14px - 14px);\n  height: 100%;\n  gap: 1rem;\n  padding: 14px 0;\n}\n\n.title {\n  width: 100%;\n  color: #45348e;\n  font: 700 1.2rem \"Mulish\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AnGPu6QUVLmJGHbZZj6v`,
	"content": `EaA9Z5EO8B4wEojoM12l`,
	"title": `rN5yFwM2hmZYz8aEMcoY`
};
export default ___CSS_LOADER_EXPORT___;
