import React, { useEffect, useState } from "react";
import style from "./EditProfile.module.css";
import { profile__validateField } from "./functions/validateField";
import {
  TextInput,
  SelectInput,
  CircularRadioInput,
  DateInput,
  HeaderNavigation,
  // @ts-ignore
} from "@viuti/recursos";
import { mockProfileData } from "../../mocks/profile";

const EditProfile = () => {
  const [formData, setFormData] = useState(mockProfileData);
  const [initialData, setInitialData] = useState(mockProfileData);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, boolean>>({});

  useEffect(() => {
    setFormData(mockProfileData);
    setInitialData(mockProfileData);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const isValid = profile__validateField(name, value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: !isValid,
    }));
  };

  useEffect(() => {
    const isFormChanged = Object.keys(formData).some(
      (key) => formData[key] !== initialData[key]
    );
    const hasErrors = Object.keys(errors).some((key) => errors[key]);
    setIsButtonDisabled(!isFormChanged || hasErrors);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const handleSave = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setInitialData(formData);
      setIsButtonDisabled(true);
    }, 2000);
  };

  const buttonProps = {
    textBttn: "Guardar",
    handleClick: handleSave,
    isDisabled: isButtonDisabled,
    isLoading: isLoading,
    isPrimary: true,
  };

  return (
    <div className={style.view__container}>
      <HeaderNavigation
        title={"Editar perfil"}
        previousAction={() => window.history.back()}
        buttonProps={buttonProps}
      />
      <form className={style.container}>
        <div className={style.row}>
          <TextInput
            width={"100%"}
            flex={1}
            label="Nombre"
            name="firstName"
            value={formData.firstName}
            handleChange={handleChange}
            error={
              errors.firstName &&
              "El nombre debe tener al menos 3 letras y menos de 20"
            }
            touched
          />
          <TextInput
            width={"100%"}
            flex={1}
            label="Apellido"
            name="lastName"
            value={formData.lastName}
            handleChange={handleChange}
            error={
              errors.lastName &&
              "El apellido debe tener al menos 3 letras y menos de 20"
            }
            touched
          />
        </div>
        <div className={style.row}>
          <TextInput
            width={"100%"}
            flex={1}
            label="Documento"
            name="document"
            value={formData.document}
            handleChange={handleChange}
            error={errors.document && "El documento es inválido"}
            touched
          />
          <TextInput
            width={"100%"}
            flex={1}
            label="Correo electrónico"
            name="email"
            value={formData.email}
            handleChange={handleChange}
            error={errors.email && "El correo electrónico es inválido"}
            touched
          />
        </div>
        <div className={style.row}>
          <DateInput
            label="Fecha de nacimiento"
            width={"100%"}
            flex={1}
            error={errors.birthDate && "La fecha de nacimiento es inválida"}
            touched
            inputProps={{
              value: formData.birthDate,
              onChange: handleChange,
              name: "birthDate",
              type: "date",
            }}
          />
          <div className={style.gender_input__container}>
            <p className={style.gender_group__label}>Genero</p>
            <span className={style.gender_group__container}>
              <button
                className={style.gender_field}
                onClick={() => setFormData({ ...formData, gender: "male" })}
                type="button"
              >
                <CircularRadioInput
                  checked={formData.gender === "male"}
                  setCheck={() => {}}
                />
                <p className={style.gender_field__label}>Masculino</p>
              </button>
              <button
                className={style.gender_field}
                onClick={() => setFormData({ ...formData, gender: "female" })}
                type="button"
              >
                <CircularRadioInput
                  checked={formData.gender === "female"}
                  setCheck={() => {}}
                />
                <p className={style.gender_field__label}>Femenino</p>
              </button>
            </span>
          </div>
        </div>
        <div className={style.row}>
          <TextInput
            width={"100%"}
            flex={1}
            label="Telefono móvil"
            name="phone"
            value={formData.phone}
            handleChange={handleChange}
            error={
              errors.phone &&
              "El número de telefono debe tener al menos 10 digitos"
            }
            touched
          />
          <SelectInput
            label="País"
            name="country"
            value={formData.country}
            handleChange={handleChange}
            items={[{ value: "Perú", name: "Perú" }]}
            error={errors.country && "El país es inválido"}
            touched
          />
        </div>
      </form>
    </div>
  );
};

export default EditProfile;
