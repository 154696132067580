import React from "react";
import { HiOutlineXMark } from "react-icons/hi2";
import style from "./styles/modalHeader.module.css";

interface IModalHeaderProps {
  title: string;
  onCloseModal: () => void;
}

const ModalHeader: React.FC<IModalHeaderProps> = ({ title, onCloseModal }) => {
  return (
    <header className={style.modalHeader__container}>
      <h3 id="modal-title">{title}</h3>
      <button
        onClick={() => onCloseModal()}
        className={style.close_button}
        aria-label="Close"
      >
        <HiOutlineXMark size={22} color="#B5B5B5" />
      </button>
    </header>
  );
};

export default ModalHeader;
