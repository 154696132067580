// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Estilo para la superposición que oscurece el fondo */
.lkcAqpuV1x7PnTTwW93_ {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000040;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Estilo para el diálogo */
.lpyXR7fup4rxKI1XV7Is {
  background-color: #ffffff;
  width: 100%;
  max-width: 550px;
  padding: 1.125rem 1.5rem;
  margin: auto;
  border: none;
  border-radius: 8px;
  box-shadow: 0 10px 15px -3px #0000001a;
  display: grid;
  gap: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/wrapper/ModalWrapper/styles/modalWrapper.module.css"],"names":[],"mappings":"AAAA,uDAAuD;AACvD;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,YAAY;EACZ,aAAa;EACb,2BAA2B;EAC3B,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA,2BAA2B;AAC3B;EACE,yBAAyB;EACzB,WAAW;EACX,gBAAgB;EAChB,wBAAwB;EACxB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,sCAAsC;EACtC,aAAa;EACb,SAAS;AACX","sourcesContent":["/* Estilo para la superposición que oscurece el fondo */\n.modalWrapper__overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background-color: #00000040;\n  z-index: 999;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n/* Estilo para el diálogo */\n.modalWrapper__dialog {\n  background-color: #ffffff;\n  width: 100%;\n  max-width: 550px;\n  padding: 1.125rem 1.5rem;\n  margin: auto;\n  border: none;\n  border-radius: 8px;\n  box-shadow: 0 10px 15px -3px #0000001a;\n  display: grid;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper__overlay": `lkcAqpuV1x7PnTTwW93_`,
	"modalWrapper__dialog": `lpyXR7fup4rxKI1XV7Is`
};
export default ___CSS_LOADER_EXPORT___;
