import { BASE_ROUTE } from "@Models/routes";
import HomeRoute from "@Routes/context.route";
import { BrowserRouter as Router } from "react-router-dom";

// Redux

// Route page of the application
export default function App() {
  return (
    <Router basename={BASE_ROUTE}>
      <HomeRoute />
    </Router>
  );
}
