import { getApiDistricts_adapter } from "@Adapters/locationsAdapters/locationAdapters.adapter";
import { ILocation } from "@Components/ui/molecules/BillingCard/ModalPlanEdit/interfaces/IModalPlanEdit.interface";

import { useEffect, useState } from "react";

export const useDistricts = (
  idProvince: any,
  setCriticalError: any,
  setLoadingLocation: any
) => {
  const [districts, setDistricts] = useState<ILocation[]>([]);
  useEffect(() => {
    (async () => {
      if (idProvince) {
        setLoadingLocation("Buscando distritos...");
        const response = await getApiDistricts_adapter(idProvince);
        if (!response.isSuccess) {
          setCriticalError(
            "Hubo un error al cargar los distritos, por favor vuelve a intentarlo"
          );
        }
        setLoadingLocation("");
        setDistricts(response.data);
      }
    })();
    // eslint-disable-next-line
  }, [idProvince]);
  return { districts, setDistricts };
};
