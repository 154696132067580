import React from "react";
import style from "./CurrentSubscriptionBanner.module.css";
import { HiBell, HiExclamationCircle } from "react-icons/hi2";
import { formatDate, formatToSoles } from "@Utilities/FormatsHandler";
// @ts-ignore
import { DualBars } from "@viuti/recursos";
import { IPlanStore, usePlanStore } from "@Stores/usePlanStore";
import { ICurrentPlan } from "@Models/interfaces/plan";

const CurrentSubscriptionBanner = () => {
	const { currentPlanFetchResponse, currentPlan } = usePlanStore() as IPlanStore;
	return (
		<section className={style.payment_details__container}>
			{currentPlanFetchResponse.status === "loading" ? (
				<DualBars />
			) : (
				<LoadedContent currentPlan={currentPlan} />
			)}
		</section>
	);
};

interface ILoadedContentProps {
	currentPlan: ICurrentPlan;
}
const LoadedContent: React.FC<ILoadedContentProps> = ({ currentPlan }) => {
	if (currentPlan.isFreeTrial)
		return (
			<>
				<span className={style.payment_period__container}>
					<HiExclamationCircle size={18} color="#DE372F" />
					<p className={style.payment_period}>Sin periodo de suscripción</p>
				</span>
				<p className={style.payment_details__message}>
					Estás usando la versión gratuita de Prikly. Puedes seguir usando la plataforma
					sin costo alguno con las funcionalidades básicas incluidas.
				</p>
			</>
		);

	return (
		<>
			<span className={style.payment_period__container}>
				<p className={style.payment_period}>
					<HiBell size={18} color="#45348E" />
					<b>Pago {currentPlan.subscription.name}</b>
				</p>
			</span>
			{currentPlan.isPaymentOnValidation ? (
				<p className={style.payment_details__message}>
					Estamos validando tu pago. Mientras tanto, disfruta de los beneficios de tu
					plan.
				</p>
			) : (
				<p className={style.payment_details__message}>
					{currentPlan.planStatus.billStatusMessage}
				</p>
			)}
		</>
	);
};

export default CurrentSubscriptionBanner;
