import { getApiProvinces_adapter } from "@Adapters/locationsAdapters/locationAdapters.adapter";
import { ILocation } from "@Components/ui/molecules/BillingCard/ModalPlanEdit/interfaces/IModalPlanEdit.interface";

import { useEffect, useState } from "react";

export const useProvinces = (
  idDepartament: any,
  setCriticalError: any,
  setLoadingLocation: any
) => {
  const [provinces, setProvinces] = useState<ILocation[]>([]);
  useEffect(() => {
    (async () => {
      if (idDepartament) {
        setLoadingLocation("Buscando provincias...");
        const response = await getApiProvinces_adapter(idDepartament);
        if (!response.isSuccess) {
          setCriticalError(
            "Hubo un error al cargar las provincias, por favor vuelve a intentarlo"
          );
        }
        setLoadingLocation("");
        setProvinces(response.data);
      } else {
        setProvinces([]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idDepartament]);
  return { provinces, setProvinces };
};
