// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sU1Vi1SETiEEv70pgw3b {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #e5e5e5;

  & h2 {
    font-family: "Mulish", sans-serif;
    font-weight: 700;
    font-size: 1.2rem;
    color: #45348e;
    width: 100%;
  }

  & > section {
    width: 100%;
  }
}

.UP4KvKkTsDibRWCWOIbU {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.GkwjXrgGApL4oQdan1S7 {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.UGK2t22tW0Xvatocbn2E {
  color: #45348e;
  font: 700 1rem "Mulish", sans-serif;
}

.jlru1WNKKMJmpg8iLG6S {
  color: #000000;
  font: 500 0.875rem "Mulish", sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/BillingCard/BillingCard.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;;EAEzB;IACE,iCAAiC;IACjC,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;IACd,WAAW;EACb;;EAEA;IACE,WAAW;EACb;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,QAAQ;AACV;;AAEA;EACE,cAAc;EACd,mCAAmC;AACrC;;AAEA;EACE,cAAc;EACd,uCAAuC;AACzC","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 32px;\n  width: 100%;\n  height: 100%;\n  text-align: left;\n  padding: 16px;\n  border-radius: 12px;\n  border: 1px solid #e5e5e5;\n\n  & h2 {\n    font-family: \"Mulish\", sans-serif;\n    font-weight: 700;\n    font-size: 1.2rem;\n    color: #45348e;\n    width: 100%;\n  }\n\n  & > section {\n    width: 100%;\n  }\n}\n\n.billingData {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  width: 100%;\n}\n\n.billingItem {\n  display: flex;\n  justify-content: space-between;\n  gap: 5px;\n}\n\n.billingTitle {\n  color: #45348e;\n  font: 700 1rem \"Mulish\", sans-serif;\n}\n\n.billingValue {\n  color: #000000;\n  font: 500 0.875rem \"Mulish\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `sU1Vi1SETiEEv70pgw3b`,
	"billingData": `UP4KvKkTsDibRWCWOIbU`,
	"billingItem": `GkwjXrgGApL4oQdan1S7`,
	"billingTitle": `UGK2t22tW0Xvatocbn2E`,
	"billingValue": `jlru1WNKKMJmpg8iLG6S`
};
export default ___CSS_LOADER_EXPORT___;
