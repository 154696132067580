import { PERSONA_JURIDICA_ID, PERSONA_NATURAL_ID } from "@Models/consts";

export const types = [
  {
    name: "Persona natural",
    value: PERSONA_NATURAL_ID,
  },
  {
    name: "Persona jurídica",
    value: PERSONA_JURIDICA_ID,
  },
];

export const departments = [
  { name: "Departamento 1", value: "Departamento 1" },
  { name: "Departamento 2", value: "Departamento 2" },
  { name: "Departamento 3", value: "Departamento 3" },
];

export const provinces = [
  { name: "Provincia 1", value: "Provincia 1" },
  { name: "Provincia 2", value: "Provincia 2" },
  { name: "Provincia 3", value: "Provincia 3" },
];

export const districts = [
  { name: "Distrito 1", value: "Distrito 1" },
  { name: "Distrito 2", value: "Distrito 2" },
  { name: "Distrito 3", value: "Distrito 3" },
];
