// CircularProgress.tsx
import React, { useMemo } from "react";
import style from "./CircularProgress.module.css";

interface CircularProgressProps {
	documentsUsed: number;
	accountDocuments: number;
	width?: string;
}

const CircularProgress: React.FC<CircularProgressProps> = ({
	documentsUsed,
	accountDocuments,
	width = "100%",
}) => {
	const progress = useMemo(() => {
		return (documentsUsed / accountDocuments) * 100;
	}, [documentsUsed, accountDocuments]);

	const normalizedProgress = Math.min(Math.max(progress, 0), 100) || 0;

	const getColor = useMemo(() => {
		let color = "#EB3C3C"; // Rojo
		if (accountDocuments === 0) return color;
		if (normalizedProgress >= 0 && normalizedProgress < 25) color = "#32C576"; // Verde
		if (normalizedProgress >= 25 && normalizedProgress < 50) color = "#EBE13C"; // Amarillo
		if (normalizedProgress >= 50 && normalizedProgress < 75) color = "#EB7C3C"; // Naranja
		if (normalizedProgress >= 75 && normalizedProgress >= 100) color = "#E0E0E0"; // Gris
		return color;
	}, [normalizedProgress, accountDocuments]);

	const circumference = 2 * Math.PI * 45;
	const offset = circumference - ((100 - normalizedProgress) / 100) * circumference;

	const progressStyle = {
		strokeDasharray: `${circumference} ${circumference}`,
		strokeDashoffset: offset,
		stroke: getColor,
	};

	return (
		<div className={style.container} style={{ width }}>
			<svg className={style.progressRing} viewBox="0 0 100 100">
				<circle className={style.progressRingCircle} cx="50" cy="50" r="45" />
				<circle
					className={style.progressRingCircle}
					cx="50"
					cy="50"
					r="45"
					style={progressStyle}
				/>
			</svg>
			<div className={style.content}>
				{accountDocuments === 0 ? (
					<div className={style.empty_container}>
						<p>
							<b>Aún no tienes comprobantes!</b>
						</p>
						<p>Compra un paquete para emitir facturas.</p>
					</div>
				) : (
					<>
						<div className={style.fraction}>
							<b>{accountDocuments - documentsUsed}</b>
						</div>
						<div className={style.label}>Comprobantes disponibles</div>
					</>
				)}
			</div>
		</div>
	);
};

export default CircularProgress;
