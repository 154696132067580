import { useEffect, useState } from "react";
import {
  IInvoicingPackagesStore,
  useInvoicingPackagesStore,
} from "@Stores/useInvoicingPackagesStore";
import { createElectronicBillingPayment } from "@Adapters/electronicBilling/createElectronicBillingPayment";
import { IInvoicingPackage } from "@Models/interfaces/invoicing";

interface IPaymentResponse {
  status: string;
  message: string;
  loading: boolean;
  error: boolean;
}

export const useEDocumentsPaymentHandler = (modalContext) => {
  const [isPaying, setIsPaying] = useState(false);
  const [paymentResponse, setPaymentResponse] =
    useState<IPaymentResponse>(null);
  const [selectedPackage, setSelectedPackage] =
    useState<IInvoicingPackage>(null);
  const { availablePackages, total, setTotal } =
    useInvoicingPackagesStore() as IInvoicingPackagesStore;

  const handleChange = (selectedPlanId: number) => {
    const selectedPlan = availablePackages.find(
      (plan) => plan.id === selectedPlanId
    );
    setSelectedPackage(selectedPlan);
  };

  const handlePayout = async () => {
    modalContext.handleShowButtonsChange({
      ...modalContext.showButtons,
      showButtonTwo: false,
    });
    setIsPaying(true);
  };

  useEffect(() => {
    if (selectedPackage) {
      modalContext.handleButtonTwoChange({
        ...modalContext.buttonTwo,
        isDisabled: false,
        actionButtonTwo: handlePayout,
      });
    } else {
      modalContext.handleButtonTwoChange({
        ...modalContext.buttonTwo,
        isDisabled: true,
      });
    }
    modalContext.handleActionButtonCloseChange(() => {
      setSelectedPackage(null);
      setIsPaying(false);
      setPaymentResponse(null);
      modalContext?.actionButtonClose();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPackage]);

  const handleGoBack = () => {
    setIsPaying(false);
    modalContext.handleShowButtonsChange({
      ...modalContext.showButtons,
      showButtonTwo: true,
    });
  };

  const handlePaymentResponse = (response) => {
    const isSuccess = response.ok || response.isSuccess || false;
    const newResponse = {
      status: response.status,
      message: response.message,
      loading: false,
      error: !isSuccess,
    };
    setPaymentResponse(newResponse);
    setIsPaying(false);
  };

  const handleCreatePayment = async (response) => {
    setIsPaying(false);
    setPaymentResponse({
      status: "",
      message: "",
      loading: true,
      error: false,
    });

    const creationResponse = await createElectronicBillingPayment(
      response,
      selectedPackage
    );
    if (creationResponse.isSuccess) {
      const regex = /\d+/;
      const match = selectedPackage.name.match(regex);
      const packageQuantity = match ? parseInt(match[0], 10) : null;

      setTotal(total + packageQuantity);
    }
    handlePaymentResponse(creationResponse);
  };

  const handleTryAgain = () => {
    setPaymentResponse(null);
    setIsPaying(false);
    modalContext.handleShowButtonsChange({
      ...modalContext.showButtons,
      showButtonTwo: true,
    });
  };

  return {
    isPaying,
    paymentResponse,
    selectedPackage,
    availablePackages,
    handleChange,
    handleCreatePayment,
    handlePaymentResponse,
    handleTryAgain,
    handleGoBack,
  };
};
