import React, { useEffect } from "react";
import style from "./AdminAccount.module.css";
import CurrentPlanBanner from "@Components/ui/molecules/CurrentPlanBanner/CurrentPlanBanner";
import SuscriptionAction from "@Components/ui/atoms/SuscriptionAction/SuscriptionAction";
import { HiStar, HiCreditCard } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import BillingDocumentsCard from "@Components/ui/molecules/BillingDocumentsCard/BillingDocumentsCard";
import AccountInfoCard from "@Components/ui/molecules/AccountInfoCard/AccountInfoCard";
import { getElectronicBillingPackages } from "@Adapters/getElectronicBillingPackages.adapter";
import {
  IInvoicingPackagesStore,
  useInvoicingPackagesStore,
} from "@Stores/useInvoicingPackagesStore";
import { getElectronicBillingInformation } from "@Adapters/getElectronicBillingInformation.adapter";
import { IPlanStore, usePlanStore } from "@Stores/usePlanStore";
import { getCurrentPlan_adapter } from "@Adapters/suscriptions/getCurrentPlan.adapter";
import { ROUTES } from "@Models/routes";
import { UPDATE_SUBSCRIPTION_UUID } from "@Models/const/securityAccess";
// @ts-ignore
import { validateModuleAccess } from "@viuti/recursos";

const AdminAccount = () => {
  const navigate = useNavigate();
  const { currentPlanFetchResponse, setCurrentPlanFetchResponse } =
    usePlanStore() as IPlanStore;
  const {
    setAvailablePackages,
    availablePackagesFetchResponse,
    setAvailablePackagesFetchResponse,
    setTotal,
    setUsed,
    stockFetchResponse,
    setTotalFetchResponse,
  } = useInvoicingPackagesStore() as IInvoicingPackagesStore;

  useEffect(() => {
    (async () => {
      const promises = [];
      const actions = [];

      if (currentPlanFetchResponse.status !== "success") {
        setCurrentPlanFetchResponse({
          status: "loading",
          message: "",
        });
        promises.push(getCurrentPlan_adapter());
        actions.push("currentPlan");
      }

      if (availablePackagesFetchResponse.status !== "success") {
        setAvailablePackagesFetchResponse({
          status: "loading",
          message: "",
        });
        promises.push(getElectronicBillingPackages(setAvailablePackages));
        actions.push("availablePackages");
      }

      if (stockFetchResponse.status !== "success") {
        setTotalFetchResponse({
          status: "loading",
          message: "",
        });
        promises.push(
          getElectronicBillingInformation({
            setTotal,
            setUsed,
          })
        );
        actions.push("stock");
      }

      const responses = await Promise.all(promises);

      responses.forEach((response, index) => {
        const action = actions[index];
        if (action === "currentPlan") {
          setCurrentPlanFetchResponse({
            status: response.isSuccess ? "success" : "error",
            message: response.message,
          });
        } else if (action === "availablePackages") {
          setAvailablePackagesFetchResponse({
            status: response.isSuccess ? "success" : "error",
            message: response.message,
          });
        } else if (action === "stock") {
          setTotalFetchResponse({
            status: response.isSuccess ? "success" : "error",
            message: response.message,
          });
        }
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.container}>
      <section className={`${style.row} ${style.suscription_row}`}>
        <CurrentPlanBanner />
        <article className={style.suscription_actions__container}>
          <SuscriptionAction
            label="Planes disponibles"
            icon={<HiStar color="937CF4" size={14} />}
            onClick={() =>
              validateModuleAccess(UPDATE_SUBSCRIPTION_UUID) &&
              navigate(ROUTES.AVAILABLE_PLANS_ROUTE)
            }
          />
          {/* <SuscriptionAction
            label="Opciones de pago"
            icon={<HiCreditCard color="937CF4" size={14} />}
            onClick={() =>
              navigate(navigate, ROUTES.MANAGE_PAYMENT_METHODS_ROUTE)
            }
          /> */}
        </article>
      </section>
      <section className={`${style.row} ${style.suscription_row}`}>
        <AccountInfoCard />
        <BillingDocumentsCard />
      </section>
    </div>
  );
};

export default AdminAccount;
