// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CircularProgress.module.css */
.Ux16YPxwf_a2AYdy1qXN {
	position: relative;
	height: auto;
	aspect-ratio: 1;
}

.nEZqcAi5AVjbg7OiFMcx {
	width: 100%;
	height: 100%;
	transform: rotate(-90deg);
}

.GmCJ98Ul5OmYcYtoKxxG {
	fill: none;
	stroke-width: 4;
	stroke-linecap: round;
	transition: stroke-dashoffset 500ms ease-in-out, stroke 500ms ease-in-out;
}

.GmCJ98Ul5OmYcYtoKxxG:nth-child(1) {
	stroke: #e0e0e0;
}

.GmCJ98Ul5OmYcYtoKxxG:nth-child(2) {
	stroke-dashoffset: 0;
}

.rvG1r3fKWWy14inq8WSI {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	width: calc(100% - 25px - 25px);
}

.NiYlWFDGTr8r2v8vnR7Z {
	width: 100%;
	display: flex;
	flex-direction: column;
	font-size: 0.875rem;
	gap: 8px;
}

.TcsYOMR8eFJf2La6XtYc {
	font-size: 2rem;
	font-weight: 600;
	margin-bottom: 5px;
}

.en4vMCVvkJuRVMkN2Nu2 {
	font-size: 1rem;
	color: #989898;
}

.KHRzQmtknV4B4VFlqfNO {
	font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/CircularProgress/CircularProgress.module.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;CACC,kBAAkB;CAClB,YAAY;CACZ,eAAe;AAChB;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,yBAAyB;AAC1B;;AAEA;CACC,UAAU;CACV,eAAe;CACf,qBAAqB;CACrB,yEAAyE;AAC1E;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,oBAAoB;AACrB;;AAEA;CACC,kBAAkB;CAClB,QAAQ;CACR,SAAS;CACT,gCAAgC;CAChC,kBAAkB;CAClB,+BAA+B;AAChC;;AAEA;CACC,WAAW;CACX,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,QAAQ;AACT;;AAEA;CACC,eAAe;CACf,gBAAgB;CAChB,kBAAkB;AACnB;;AAEA;CACC,eAAe;CACf,cAAc;AACf;;AAEA;CACC,eAAe;AAChB","sourcesContent":["/* CircularProgress.module.css */\n.container {\n\tposition: relative;\n\theight: auto;\n\taspect-ratio: 1;\n}\n\n.progressRing {\n\twidth: 100%;\n\theight: 100%;\n\ttransform: rotate(-90deg);\n}\n\n.progressRingCircle {\n\tfill: none;\n\tstroke-width: 4;\n\tstroke-linecap: round;\n\ttransition: stroke-dashoffset 500ms ease-in-out, stroke 500ms ease-in-out;\n}\n\n.progressRingCircle:nth-child(1) {\n\tstroke: #e0e0e0;\n}\n\n.progressRingCircle:nth-child(2) {\n\tstroke-dashoffset: 0;\n}\n\n.content {\n\tposition: absolute;\n\ttop: 50%;\n\tleft: 50%;\n\ttransform: translate(-50%, -50%);\n\ttext-align: center;\n\twidth: calc(100% - 25px - 25px);\n}\n\n.empty_container {\n\twidth: 100%;\n\tdisplay: flex;\n\tflex-direction: column;\n\tfont-size: 0.875rem;\n\tgap: 8px;\n}\n\n.fraction {\n\tfont-size: 2rem;\n\tfont-weight: 600;\n\tmargin-bottom: 5px;\n}\n\n.separator {\n\tfont-size: 1rem;\n\tcolor: #989898;\n}\n\n.label {\n\tfont-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Ux16YPxwf_a2AYdy1qXN`,
	"progressRing": `nEZqcAi5AVjbg7OiFMcx`,
	"progressRingCircle": `GmCJ98Ul5OmYcYtoKxxG`,
	"content": `rvG1r3fKWWy14inq8WSI`,
	"empty_container": `NiYlWFDGTr8r2v8vnR7Z`,
	"fraction": `TcsYOMR8eFJf2La6XtYc`,
	"separator": `en4vMCVvkJuRVMkN2Nu2`,
	"label": `KHRzQmtknV4B4VFlqfNO`
};
export default ___CSS_LOADER_EXPORT___;
