import { ApiResponse, clientsBaseUrl, getData } from "@Services/constServices";

export const getSearchReniec_service = async (
  documentNumber: string
): Promise<ApiResponse> => {
  const url = `${clientsBaseUrl}/Cliente/SearchClientReniec2?DocumentNumber=${documentNumber}`;
  const successMessage = "Encontrado";
  const errorMessage = "No encontrado";
  return getData(url, successMessage, errorMessage);
};
