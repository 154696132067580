import { useEffect } from "react";
import { Error, Form } from "../interfaces/IModalPlanEdit.interface";

const useFormReset = ({
  form,
  setForm,
  setError,
}: {
  form: Form;
  setForm: React.Dispatch<React.SetStateAction<Form>>;
  setError: React.Dispatch<React.SetStateAction<Error>>;
}) => {
  useEffect(() => {
    setError((prevForm: Error) => ({
      ...prevForm,
      type: "",
      DNINumber: "",
      RUCNumber: "",
      name: "",
      reasonSocial: "",
    }));
    setForm((prevForm: Form) => ({
      ...prevForm,
      DNINumber: "",
      RUCNumber: "",
      name: "",
      reasonSocial: "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.typeId]);
};

export default useFormReset;
