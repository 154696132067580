import { accountBaseUrl, getData } from "@Services/constServices";

export const getCurrentPlan_service = async () => {
  const url = `${accountBaseUrl}/Account/PlanSelected`;

  const successMessage = "Plan actual obtenido";
  const errorMessage = "Plan actual no obtenidos";

  return await getData(url, successMessage, errorMessage);
};
