import { ISubscription, ISubscriptionPlan } from "@Models/interfaces/plan";
import { createAnnualSubscriptionPayment_service } from "@Services/subscriptions/createAnnualSubscriptionPayment";

export const createAnnualSubscriptionPayment = async (
  data: any,
  plan: ISubscriptionPlan,
  subscription: ISubscription
) => {
  const payload = {
    planId: plan.id,
    issuerId: data.issuer_id,
    paymentMethodId: data.payment_method_id,
    cardholderEmail: data.payer.email,
    token: data.token,
    installments: data.installments,
    identificationNumber: data.payer.identification.number,
    identificationType: data.payer.identification.type,
  };

  const response = await createAnnualSubscriptionPayment_service(payload);

  // const response = {
  // 	isSuccess: true,
  // 	data: null,
  // 	status: 200,
  // 	message: 'Transacción exitosa.',
  // };

  return response;
};
