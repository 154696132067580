import React, { useContext } from "react";
import style from "./ElectronicInvoicingModal.module.css";
import {
  ReportModalStateContext,
  ReportModalStateContextProps,
} from "@Hooks/ReportModalStateContext";

import PackageList from "@Components/ui/molecules/PackageList/PackageList";
import PaymentProcessing from "@Components/ui/molecules/PaymentProcessing/PaymentProcessing";
import PaymentResponse from "@Components/ui/molecules/PaymentResponse/PaymentResponse";
import { useEDocumentsPaymentHandler } from "@Hooks/useEDocumentsPaymentHandler";

const ElectronicInvoicingModal = () => {
  const modalContext = useContext(
    ReportModalStateContext
  ) as ReportModalStateContextProps;

  const {
    isPaying,
    paymentResponse,
    selectedPackage,
    availablePackages,
    handleChange,
    handleCreatePayment,
    handlePaymentResponse,
    handleTryAgain,
  } = useEDocumentsPaymentHandler(modalContext);

  const renderContent = () => {
    if (isPaying) {
      return (
        <PaymentProcessing
          name={selectedPackage?.name}
          amount={selectedPackage?.price?.withIGV}
          handleCreatePayment={handleCreatePayment}
          handlePaymentResponse={handlePaymentResponse}
          tempType="compra"
        />
      );
    }

    if (paymentResponse === null) {
      return (
        <PackageList
          availablePackages={availablePackages}
          selectedPackage={selectedPackage}
          handleChange={handleChange}
        />
      );
    }

    return (
      <PaymentResponse
        paymentResponse={paymentResponse}
        handleTryAgain={handleTryAgain}
        handleClose={modalContext?.actionButtonClose}
      />
    );
  };

  return <div className={style.container}>{renderContent()}</div>;
};

export default ElectronicInvoicingModal;
