import { create } from "zustand";

export interface IBillingInformationOverview {
  id: number | null;
  entity: {
    id: number;
    name: string;
  };
  document: {
    id: number;
    name: string;
  };
  identity: {
    number: string;
    name: string;
  };
  email: string;
  address: string;
  department: {
    id: number;
    name: string;
  };
  province: {
    id: number;
    name: string;
  };
  district: {
    id: number;
    name: string;
  };
  voucher: {
    id: number;
    name: string;
  };
}

export interface IBillingStore {
  billingInformation: IBillingInformationOverview;
  setBillingInformation: (
    billingInformation: IBillingInformationOverview
  ) => void;

  billingInformationFetchResponse: IFetchReportResponse;
  setBillingInformationFetchResponse: (response: IFetchReportResponse) => void;
}

export const useBillingStore = create((set) => ({
  billingInformation: {
    id: null,
    entity: null,
    document: null,
    identity: null,
    email: null,
    address: null,
    department: null,
    province: null,
    district: null,
    voucher: null,
  },
  setBillingInformation: (billingInformation: IBillingInformationOverview) =>
    set({ billingInformation }),
  billingInformationFetchResponse: {
    status: "loading",
    message: "",
  },
  setBillingInformationFetchResponse: (response: IFetchReportResponse) =>
    set({ billingInformationFetchResponse: response }),
}));
