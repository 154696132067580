import { accountBaseUrl, putData } from "@Services/constServices";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const updateBillingData_service = async (data) => {
  const url = `${accountBaseUrl}/BillingInformation/EditBillinghInformation`;
  const edita_datos_de_facturacion = 52;
  trackUserAction(edita_datos_de_facturacion);
  const successMessage = "Datos de facturación guardados";
  const errorMessage =
    "Lo sentimos, hubo un error al guardar los datos de facturación";
  return putData(url, data, successMessage, errorMessage);
};
