import { accountBaseUrl, postData } from "@Services/constServices";

export const createAnnualSubscriptionPayment_service = async (data) => {
  const url = `${accountBaseUrl}/PurchasesAndSubscriptions/ProcessAnnualSubscription`;
  const successMessage = "Compra realizada";
  const errorMessage = "Lo sentimos, hubo un error al guardar la suscripción";
  return postData(url, data, successMessage, errorMessage);

  // return {
  //   isSuccess: true,
  //   status: 200,
  //   data: null,
  //   message: "Datos de facturación guardados",
  // };
};
