import {
  getApiDepartaments_service,
  getApiDistricts_service,
  getApiProvinces_service,
} from "@Services/locationsServices/locationsServices.service";

export const getApiDepartaments_adapter = async () => {
  const response = await getApiDepartaments_service();

  if (!response.isSuccess) {
    return {
      isSuccess: false,
      status: response.status,
      message: response.message,
      data: [],
    };
  }
  return {
    ...response,
    data: response.data.map((departament: any) => ({
      value: departament.idDepartament,
      name: departament.nameDepartament,
    })),
  };
};

export const getApiProvinces_adapter = async (
  idDepartament: number | string
) => {
  const response = await getApiProvinces_service(idDepartament);

  if (!response.isSuccess) {
    return {
      isSuccess: false,
      status: response.status,
      message: response.message,
      data: [],
    };
  }
  return {
    ...response,
    data: response.data.map((province: any) => ({
      value: province.idProvince,
      name: province.provinceName,
    })),
  };
};

export const getApiDistricts_adapter = async (idProvincia: number | string) => {
  const response = await getApiDistricts_service(idProvincia);

  if (!response.isSuccess) {
    return {
      isSuccess: false,
      status: response.status,
      message: response.message,
      data: [],
    };
  }
  return {
    ...response,
    data: response.data.map((district: any) => ({
      value: district.idDistrict,
      name: district.nameDistrict,
    })),
  };
};
