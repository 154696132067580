// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WAQ6uWw8y5N_MpXh8aIH {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(200px);
  margin: 0;
  border-radius: 16px;
}

.uuYkPFNpG79jxtfb3UBy {
  width: 200px;
  height: 200px;
}

.iIqtoqxcmRPttQ_aYQlm {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 24px;
}

.O6gbbxMbJhp_A_MyHJrC {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DCzdEtGwJo1E0Vti8xDK {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
}

.A2_NrqR5A2cI3dycQ5z0 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 1.25rem 2rem;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  transition: border-color 50ms ease-in-out, background-color 150ms ease-in-out;

  &:hover {
    border-color: #8f85b9;
    background-color: #ede8ff;
  }
}

.UOFRJCSid4PlNyli9yb5 {
  font: 800 1rem "Mulish", sans-serif;
  color: #937cf4;
}

.UdkcHWVIm7SK5XZWODPP {
  font: 400 0.8rem "Mulish", sans-serif;
  color: #1a0063;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/HomePage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,4BAA4B;EAC5B,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,4DAA4D;EAC5D,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,QAAQ;EACR,WAAW;EACX,qBAAqB;EACrB,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB;EACzB,6EAA6E;;EAE7E;IACE,qBAAqB;IACrB,yBAAyB;EAC3B;AACF;;AAEA;EACE,mCAAmC;EACnC,cAAc;AAChB;;AAEA;EACE,qCAAqC;EACrC,cAAc;AAChB","sourcesContent":[".loader_container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  backdrop-filter: blur(200px);\n  margin: 0;\n  border-radius: 16px;\n}\n\n.loader_icon {\n  width: 200px;\n  height: 200px;\n}\n\n.view__container {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  gap: 24px;\n}\n\n.search_container {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.report_list {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));\n  gap: 16px;\n}\n\n.report_element {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n  width: 100%;\n  padding: 1.25rem 2rem;\n  border-radius: 8px;\n  background-color: #ffffff;\n  border: 1px solid #e0e0e0;\n  transition: border-color 50ms ease-in-out, background-color 150ms ease-in-out;\n\n  &:hover {\n    border-color: #8f85b9;\n    background-color: #ede8ff;\n  }\n}\n\n.report_element_title {\n  font: 800 1rem \"Mulish\", sans-serif;\n  color: #937cf4;\n}\n\n.report_element_description {\n  font: 400 0.8rem \"Mulish\", sans-serif;\n  color: #1a0063;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader_container": `WAQ6uWw8y5N_MpXh8aIH`,
	"loader_icon": `uuYkPFNpG79jxtfb3UBy`,
	"view__container": `iIqtoqxcmRPttQ_aYQlm`,
	"search_container": `O6gbbxMbJhp_A_MyHJrC`,
	"report_list": `DCzdEtGwJo1E0Vti8xDK`,
	"report_element": `A2_NrqR5A2cI3dycQ5z0`,
	"report_element_title": `UOFRJCSid4PlNyli9yb5`,
	"report_element_description": `UdkcHWVIm7SK5XZWODPP`
};
export default ___CSS_LOADER_EXPORT___;
