import React from "react";
import style from "./SimpleFeaturesCard.module.css";
import {
  IPlanFeature,
  ISubscriptionPlan,
  ISubscription,
} from "@Models/interfaces/plan";
import { formatToSoles } from "@Utilities/FormatsHandler";
import FeatureItem from "@Components/ui/atoms/FeatureItem/FeatureItem";

interface ISimpleFeaturesCard {
  color: T_HexColor;
  features: IPlanFeature[];
  isBefore: boolean;
}
const SimpleFeaturesCard: React.FC<ISimpleFeaturesCard> = ({
  isBefore,
  features,
  color,
}) => {
  if (!features || !color) return null;

  return (
    <div className={style.container}>
      <div className={style.header}>
        <p className={style.title}>{isBefore ? "Antes" : "Ahora"}</p>
      </div>
      <div className={style.body}>
        {features.map((feature) => {
          return (
            <FeatureItem
              key={feature.name}
              {...feature}
              backgroundColor={color}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SimpleFeaturesCard;
