import { Form } from "../interfaces/IModalPlanEdit.interface";
import { PERSONA_NATURAL_ID } from "@Models/consts";
import { postBillingData } from "@Adapters/postBillingData.adapter";
import { updateBillingData } from "@Adapters/updateBillingData.adapter";
import { getBillingData_adapter } from "@Adapters/getBillingData.adapter";
import { IBillingInformationOverview } from "@Stores/useBillingStore";

export const handleSubmit = async (
  form: Form,
  setters: {
    setToaster: (message: string, status: number, loading: boolean) => void;
    setButtonLoading: (loading: boolean) => void;
    setBillingInformation: (
      billingInformation: IBillingInformationOverview
    ) => void;
  },
  successCallback: () => void
) => {
  const { setToaster, setButtonLoading, setBillingInformation } = setters;

  setButtonLoading(true);

  const DNI_ID = 2;
  const RUC_ID = 1;
  const BOLETA_ID = 1;
  const FACTURA_ID = 2;

  let response;

  const creationPayload = {
    documentNumber: form.identityNumber,
    fullName: form.identityName,
    addres: form.address,
    districtId: Number(form.districtId),
    billingEmail: form.email,
    documentTypeId: form.typeId === PERSONA_NATURAL_ID ? DNI_ID : RUC_ID,
    billindDocument:
      form.typeId === PERSONA_NATURAL_ID ? BOLETA_ID : FACTURA_ID,
  };

  const editionForm = {
    data: {
      billingInformationId: form.billingId,
      rucOrDni: form.identityNumber,
      fullNameOrBusinessname: form.identityName,
      addres: form.address,
      districtId: Number(form.districtId),
      billingEmail: form.email,
      documentTypeId: form.typeId === PERSONA_NATURAL_ID ? DNI_ID : RUC_ID,
      billindDocumentTypeId:
        form.typeId === PERSONA_NATURAL_ID ? BOLETA_ID : FACTURA_ID,
    },
  };

  if (!form.billingId) {
    response = await postBillingData(creationPayload);
  } else {
    response = await updateBillingData(editionForm);
  }

  setToaster(response.message, response.status, true);

  if (response.isSuccess) {
    getBillingData_adapter({ setBillingInformation });

    setTimeout(() => {
      successCallback();
    }, 1500);
  } else {
    setButtonLoading(false);
  }
};
