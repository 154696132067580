export const profile__validateField = (name, value) => {
  switch (name) {
    case "firstName":
    case "lastName":
      return value.length >= 3 && value.length <= 20;
    case "document":
      return value.length <= 8 && value.length >= 6;
    case "email":
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    case "birthDate":
      const birthDate = new Date(value);
      const age = new Date().getFullYear() - birthDate.getFullYear();
      return age >= 18;
    case "phone":
      return value.length <= 11 && value.length >= 9;
    default:
      return true;
  }
};
