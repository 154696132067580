export const BASE_ROUTE = "/mi-cuenta";

export const ROUTES = {
  AVAILABLE_PLANS_ROUTE: "/planes",
  EDIT_PROFILE_ROUTE: "/editar-perfil",
  HOME_ROUTE: "/",
  MANAGE_SUBSCRIPTION_ROUTE: "/gestionar-plan",
  MANAGE_PAYMENT_METHODS_ROUTE: "/actualizar-medio-de-pago",
  UPDATE_PLAN_ROUTE: "/actualizar-plan/:planId/:subscriptionId",
};
