import React from "react";
import style from "./CurrentPlan.module.css";
import {
	ButtonWithoutIcon,
	DualBars,
	validateModuleAccess,
	// @ts-ignore
} from "@viuti/recursos";
import { IBillingStore, useBillingStore } from "@Stores/useBillingStore";
import { HiMiniUser } from "react-icons/hi2";
import { FaCircleCheck } from "react-icons/fa6";
import { formatToSoles } from "@Utilities/FormatsHandler";
import { useNavigate } from "react-router-dom";
import { IPlanStore, usePlanStore } from "@Stores/usePlanStore";
import { ROUTES } from "@Models/routes";
import { UPDATE_SUBSCRIPTION_UUID } from "@Models/const/securityAccess";

const CurrentPlan = () => {
	const navigate = useNavigate();
	const { currentPlan, currentPlanFetchResponse } = usePlanStore() as IPlanStore;
	const { billingInformation } = useBillingStore() as IBillingStore;

	return (
		<article className={style.container}>
			{currentPlanFetchResponse.status === "loading" ? (
				<DualBars />
			) : (
				<>
					<div className={style.content}>
						<div className={style.details}>
							<p className={style.plan_type}>Plan actual</p>
							<p className={style.plan_name}>{currentPlan.name}</p>
							<span className={style.plan_users__container}>
								<HiMiniUser size={15} color="#937CF4" />
								<p className={style.plan_users__message}>
									{currentPlan.users.unlimited
										? "Usuarios ilimitados"
										: `Hasta ${currentPlan.users.quantity} usuarios`}
								</p>
							</span>
							<p className={style.plan_price}>
								{currentPlan.subscription.price.withoutIGV ? (
									<>
										<b>
											{formatToSoles(currentPlan.subscription.price.withoutIGV)} cada{" "}
											{currentPlan.subscription.months}{" "}
											{currentPlan.subscription.months === 1 ? "mes" : "meses"}
										</b>{" "}
										+ IGV
									</>
								) : (
									<b>Gratuito</b>
								)}
							</p>
						</div>
						<hr className={style.divisor} />
						<div className={style.features__container}>
							{currentPlan.features.map((feature) => {
								return (
									<span key={feature.name} className={style.feature}>
										<FaCircleCheck size={14} color="#937CF4" />
										<p className={style.feature_name}>{feature.name}</p>
									</span>
								);
							})}
						</div>
					</div>
					<ButtonWithoutIcon
						isPrimary
						textBttn={"Cambiar plan"}
						handleClick={() =>
							validateModuleAccess(UPDATE_SUBSCRIPTION_UUID) &&
							navigate(ROUTES.AVAILABLE_PLANS_ROUTE)
						}
					/>
				</>
			)}
		</article>
	);
};

export default CurrentPlan;
