import React from "react";
import style from "./changeModalUpdatePlanPay.module.css";
import { ReportModalStateContextProps } from "@Hooks/ReportModalStateContext";
import UpdatePlanPayModal from "@Components/ui/organisms/UpdatePlanPayModal/UpdatePlanPayModal";
import { ISubscription, ISubscriptionPlan } from "@Models/interfaces/plan";

export const changeModalUpdatePlanPay = (
  modalContext: ReportModalStateContextProps,
  desiredPlan: ISubscriptionPlan,
  desiredSubscription: ISubscription,
  tempType?: "compra" | "suscripcion"
) => {
  const {
    handleTitleChange,
    handleVisibilityChange,
    handleShowButtonsChange,
    handleButtonTwoChange,
    handleChildrenChange,
    handleActionButtonCloseChange,
    handleNoSizeLimitChange,
  } = modalContext;

  const ModalChildren = (
    <UpdatePlanPayModal
      newPlan={desiredPlan}
      newSubscription={desiredSubscription}
      tempType={tempType}
    />
  );

  handleTitleChange(
    <span className={style.header_title_container}>Actualizar suscripción</span>
  );
  handleVisibilityChange({
    isVisible: true,
    setIsVisible: () => {},
  });
  handleShowButtonsChange({
    showButtonClose: true,
    showButtonOne: false,
    showButtonTwo: true,
  });
  handleChildrenChange(ModalChildren);
  handleActionButtonCloseChange(() =>
    handleVisibilityChange({
      isVisible: false,
      setIsVisible: () => {},
    })
  );
  handleNoSizeLimitChange(true);
  handleButtonTwoChange({
    textButtonTwo: "Comprar",
    actionButtonTwo: () => {},
    isDisabled: true,
    isLoading: false,
  });
};
