import React from "react";
import style from "./SimpleSubscriptionCard.module.css";
import { ISubscriptionPlan, ISubscription } from "@Models/interfaces/plan";
import { formatToSoles } from "@Utilities/FormatsHandler";

interface ISimpleSubscriptionCard {
  isCurrent: boolean;
  plan: ISubscriptionPlan;
  subscription: ISubscription;
}
const SimpleSubscriptionCard: React.FC<ISimpleSubscriptionCard> = ({
  isCurrent,
  plan,
  subscription,
}) => {
  if (!plan || !subscription) return null;

  return (
    <div className={style.container}>
      <div
        className={style.header}
        style={{ backgroundColor: plan.hexColors.primary_50 }}
      >
        <p className={style.title}>
          {isCurrent ? "Suscripción actual:" : "Tu nueva suscripción:"}
        </p>
      </div>
      <div
        className={style.body}
        style={{ backgroundColor: plan.hexColors.primary_70 }}
      >
        <h3 className={style.body__title}>
          {plan.category} {plan.name}
        </h3>
        {!isCurrent && (
          <span className={style.body__price}>
            <b>{formatToSoles(subscription.prices.withoutIGV)}</b> + IGV por{" "}
            {subscription.name}
          </span>
        )}
      </div>
    </div>
  );
};

export default SimpleSubscriptionCard;
