export const formatToSoles = (
  number: number,
  includePrefix: boolean = true
) => {
  number = Number(number);

  if (number === null || number === undefined) {
    return "";
  }

  const formatter = new Intl.NumberFormat("es-PE", {
    style: "currency",
    currency: "PEN",
    currencyDisplay: includePrefix ? "symbol" : "code",
  });

  return includePrefix
    ? formatter.format(number)
    : formatter.format(number).replace("PEN ", "");
};

export const formatDate = (date: Date | string | number): string => {
  const d = new Date(date);
  // Correctly adjust for timezone offset to avoid issues with local time
  const utcDay = d.getUTCDate();
  const utcMonth = d.getUTCMonth() + 1; // Months are zero-based
  const utcYear = d.getUTCFullYear();

  const day = String(utcDay).padStart(2, "0");
  const month = String(utcMonth).padStart(2, "0");
  const year = utcYear;

  return `${day}/${month}/${year}`;
};

export const formatShortDate = (string: string) => {
  const monthNames = [
    "ene",
    "feb",
    "mar",
    "abr",
    "may",
    "jun",
    "jul",
    "ago",
    "sep",
    "oct",
    "nov",
    "dic",
  ];
  const date = formatDate(string);
  const dateArray = date.split("/");
  const day = dateArray[0];
  const month = dateArray[1];
  const monthName = monthNames[Number(month) - 1];

  return `${day} ${monthName}.`;
};

export const capitalizeFirstLetter = (str: string): string => {
  return !str
    ? "Missing data"
    : str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
