import { create } from "zustand";
import { IInvoicingPackage } from "@Models/interfaces/invoicing";

export interface IInvoicingPackagesStore {
  total: number;
  setTotal: (total: number) => void;
  used: number;
  setUsed: (used: number) => void;
  stockFetchResponse: IFetchReportResponse;
  setTotalFetchResponse: (response: IFetchReportResponse) => void;
  availablePackages: IInvoicingPackage[];
  setAvailablePackages: (availablePlans: IInvoicingPackage[]) => void;
  availablePackagesFetchResponse: IFetchReportResponse;
  setAvailablePackagesFetchResponse: (response: IFetchReportResponse) => void;
}

export const useInvoicingPackagesStore = create((set) => ({
  total: null,
  setTotal: (total: number) => set({ total }),
  used: null,
  setUsed: (used: number) => set({ used }),
  stockFetchResponse: {
    status: "loading",
    message: "",
  },
  setTotalFetchResponse: (response: IFetchReportResponse) =>
    set({ stockFetchResponse: response }),
  availablePackages: [],
  setAvailablePackages: (availablePlans: IInvoicingPackage[]) =>
    set({ availablePackages: availablePlans }),
  availablePackagesFetchResponse: {
    status: "loading",
    message: "",
  },
  setAvailablePackagesFetchResponse: (response: IFetchReportResponse) =>
    set({ availablePackagesFetchResponse: response }),
}));

export const updateInvoicingPackagesStore = (invoicingPackagesStore: {
  total: number;
  used: number;
}) => {
  useInvoicingPackagesStore.setState(invoicingPackagesStore);
};
