import { getElectronicBillingInformation_service } from "@Services/electronicBilling/getElectronicBillingInformation";

interface IParams {
  setTotal: (stock: number) => void;
  setUsed: (used: number) => void;
}
export const getElectronicBillingInformation = async (setters: IParams) => {
  const { setTotal, setUsed } = setters;

  const response = await getElectronicBillingInformation_service();

  if (!response.isSuccess) {
    return response;
  }

  setTotal(response.data.total || 0);
  setUsed(response.data.used || 0);

  return response;
};
