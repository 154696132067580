import { PERSONA_JURIDICA_ID, PERSONA_NATURAL_ID } from "@Models/consts";
import { getBillingData_service } from "@Services/account/getBillingData.service";
import { IBillingInformationOverview } from "@Stores/useBillingStore";

interface IParams {
  setBillingInformation: (
    billingInformation: IBillingInformationOverview
  ) => void;
}
export const getBillingData_adapter = async (params: IParams) => {
  const { setBillingInformation } = params;
  const response = await getBillingData_service();

  let billingInformation: IBillingInformationOverview = {
    id: null,
    entity: null,
    document: null,
    identity: null,
    email: null,
    address: null,
    department: null,
    province: null,
    district: null,
    voucher: null,
  };
  if (response.status === 404) {
    return {
      ...response,
      isSuccess: true,
      status: 200,
      data: {},
    };
  }

  if (response.isSuccess) {
    const account_info = localStorage.getItem("account_info") ?? "";
    const account_info_json = JSON.parse(account_info);
    const defaultEmail = account_info_json.employee.email;

    billingInformation = {
      id: response.data.billingInformationId,
      entity: {
        id:
          response.data.typeDocumentId === 1
            ? PERSONA_JURIDICA_ID
            : PERSONA_NATURAL_ID,
        name:
          response.data.typeDocumentId === 1
            ? "Persona Jurídica"
            : "Persona Natural",
      },
      document: {
        id: response.data.typeDocumentId,
        name: response.data.typeDocumentName,
      },
      identity: {
        number: response.data.rucOrDni,
        name: response.data.fullNameOrBusinessname,
      },
      email: response.data.billingEmail || defaultEmail,
      address: response.data.addres,
      department: {
        id: response.data.departmentId || "",
        name: response.data.departmentName || "",
      },
      province: {
        id: response.data.provinceId || "",
        name: response.data.proviceName || "",
      },
      district: {
        id: response.data.districtId || "",
        name: response.data.districtName || "",
      },
      voucher: {
        id: response.data.typeBillingDocumentId,
        name: response.data.typeBillingDocumentName,
      },
    };

    setBillingInformation(billingInformation);
  }

  return {
    ...response,
    data: billingInformation,
  };
};
