import { IModalWrapper } from "@Components/wrapper/ModalWrapper/ModalWrapper";
import { useState, useCallback, useEffect } from "react";
import { CANCELATION_STEPS } from "./consts/cancelationSteps";
import { updateButtonSettings } from "./utilities/steps";
import { cancelSubscription } from "@Adapters/suscriptions/cancelSubscription";
import { IToasterStore, useToasterStore } from "@Stores/useToasterStore";

const useCancellationProcess = () => {
  const { setToaster } = useToasterStore() as IToasterStore;
  const [currentStep, setCurrentStep] = useState<IModalWrapper>();
  const [shouldShowModal, setShouldShowModal] = useState(false);

  const updateStep = (step: IModalWrapper, updates: any) => {
    setCurrentStep(updateButtonSettings(step, updates));
  };

  //** revert all steps
  const cancelOperation = () => {
    updateStep(CANCELATION_STEPS["CONFIRMATION"], {
      buttonRight: { handler: handleRiskAcceptance },
    });
    setShouldShowModal(false);
  };

  //** STEP 1: Initialize the first step - Confirmation */
  useEffect(() => {
    updateStep(CANCELATION_STEPS["CONFIRMATION"], {
      buttonRight: { handler: handleRiskAcceptance },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //** STEP 2: Handle user risk acceptance */
  const handleRiskAcceptance = useCallback(() => {
    updateStep(CANCELATION_STEPS["CONTINUE_CANCELATION"], {
      buttonRight: { handler: handleConfirmCancelPlan },
      buttonLeft: { handler: cancelOperation },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //** STEP 3: Handle plan cancellation confirmation */
  const [isReasonButtonAvailable, setIsReasonButtonAvailable] = useState(false);

  const handleConfirmCancelPlan = useCallback(() => {
    updateStep(
      CANCELATION_STEPS.REASON_WITHDRAWAL(() =>
        setIsReasonButtonAvailable(true)
      ),
      { buttonRight: { isDisabled: true } }
    );
  }, []);

  //** STEP 4: Update step when button availability changes */
  useEffect(() => {
    if (isReasonButtonAvailable) {
      updateStep(
        CANCELATION_STEPS.REASON_WITHDRAWAL(() =>
          setIsReasonButtonAvailable(false)
        ),
        {
          buttonRight: { isDisabled: false, handler: showTheDiscountOffer },
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReasonButtonAvailable]);

  //** STEP 5: Offer the 20% discount */
  const showTheDiscountOffer = () => {
    updateStep(CANCELATION_STEPS["DISCOUNT_PROMOTION"], {
      buttonRight: { handler: showTheSecurityTextInput },
      buttonLeft: { handler: cancelOperation },
    });
  };

  //** STEP 6: Show the security text input */
  const [securityStep, setSecurityStep] = useState(false);

  const showTheSecurityTextInput = () => {
    setCurrentStep(
      CANCELATION_STEPS["SECURITY_STEP"]((b: boolean) => setSecurityStep(b))
    );
  };

  const executeCancelation = () => {
    updateStep(
      CANCELATION_STEPS["SECURITY_STEP"]((b: boolean) => setSecurityStep(b)),
      {
        buttonRight: { isDisabled: true, isLoading: true },
      }
    );
    // const response = await cancelSubscription();
    const response = {
      isSuccess: true,
      message: "Transacción exitosa.",
    };

    response.isSuccess
      ? showLastStep()
      : (setToaster(response.message, 400, false),
        updateStep(
          CANCELATION_STEPS["SECURITY_STEP"]((b: boolean) =>
            setSecurityStep(b)
          ),
          {
            buttonRight: {
              isDisabled: false,
              isLoading: false,
              handler: executeCancelation,
            },
          }
        ));
  };

  //** Update the security step based on its state */
  useEffect(() => {
    if (
      currentStep?.title === CANCELATION_STEPS["SECURITY_STEP"](() => {}).title
    ) {
      if (securityStep) {
        updateStep(
          CANCELATION_STEPS["SECURITY_STEP"]((b: boolean) =>
            setSecurityStep(b)
          ),
          {
            buttonRight: { isDisabled: false, handler: executeCancelation },
          }
        );
      } else {
        showTheSecurityTextInput();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityStep]);

  //** Show the final confirmation step */
  const showLastStep = async () => {
    setCurrentStep(CANCELATION_STEPS["DELETE_CONFIRMATION"]);
  };

  //** Update the modal visibility */
  const finishCancellation = () => {
    cancelOperation();
    setShouldShowModal(false);
  };

  useEffect(() => {
    if (currentStep?.title === CANCELATION_STEPS["DELETE_CONFIRMATION"].title) {
      updateStep(CANCELATION_STEPS["DELETE_CONFIRMATION"], {
        buttonRight: { handler: finishCancellation },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep?.title]);

  return {
    currentCancelationProps: currentStep,
    visibleProps: {
      isVisible: shouldShowModal,
      changeVisibilities: setShouldShowModal,
    },
    cancelOperation: cancelOperation,
  };
};

export default useCancellationProcess;
