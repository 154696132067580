import React from "react";
import style from "./changeModalBuyInvoicing.module.css";

import { ReportModalStateContextProps } from "@Hooks/ReportModalStateContext";

import ElectronicInvoicingModal from "@Components/ui/organisms/ElectronicInvoicingModal/ElectronicInvoicingModal";

export const changeModalBuyInvoicing = (
  modalContext: ReportModalStateContextProps
) => {
  const {
    handleTitleChange,
    handleVisibilityChange,
    handleShowButtonsChange,
    handleButtonTwoChange,
    handleChildrenChange,
    handleActionButtonCloseChange,
    handleNoSizeLimitChange,
  } = modalContext;

  const ModalChildren = <ElectronicInvoicingModal />;

  handleTitleChange(
    <span className={style.header_title_container}>
      Facturación electrónica
    </span>
  );
  handleVisibilityChange({
    isVisible: true,
    setIsVisible: () => {},
  });
  handleShowButtonsChange({
    showButtonClose: true,
    showButtonOne: false,
    showButtonTwo: true,
  });
  handleChildrenChange(ModalChildren);
  handleActionButtonCloseChange(() =>
    handleVisibilityChange({
      isVisible: false,
      setIsVisible: () => {},
    })
  );
  handleNoSizeLimitChange(true);
  handleButtonTwoChange({
    textButtonTwo: "Comprar",
    actionButtonTwo: () => {},
    isDisabled: true,
    isLoading: false,
  });
};
