import { getData, loungesBaseUrl } from "@Services/constServices";

export const getApiDepartaments_service = async () => {
  const url = `${loungesBaseUrl}/Billing/Departaments`;
  const successMessage = "Departamentos encontrados";
  const errorMessage = "Departamentos no encontrados";
  return getData(url, successMessage, errorMessage);
};

export const getApiProvinces_service = async (idDepartamento: any) => {
  const url = `${loungesBaseUrl}/Billing/Provinces?IdDepartament=${idDepartamento}`;
  const successMessage = "Provincias encontradas";
  const errorMessage = "Provincias no encontradas";
  return getData(url, successMessage, errorMessage);
};

export const getApiDistricts_service = async (idProvincia: any) => {
  const url = `${loungesBaseUrl}/Billing/Districts?IdProvince=${idProvincia}`;
  const successMessage = "Distritos encontrados";
  const errorMessage = "Distritos no encontrados";
  return getData(url, successMessage, errorMessage);
};
