import { IBillingData } from "@Models/interfaces/BillingData";
import { postBillingData_service } from "@Services/account/postBillingData.service";

export const postBillingData = async (data: IBillingData) => {
  const adaptData = {
    rucOrDni: String(data.documentNumber),
    fullNameOrBusinessname: String(data.fullName),
    addres: String(data.addres),
    districtId: Number(data.districtId),
    billingEmail: String(data.billingEmail),
    documentTypeId: Number(data.documentTypeId),
    billindDocumentTypeId: Number(data.billindDocument),
  };
  const response = await postBillingData_service(adaptData);
  return response;
};
