import { IPlanFeature, ISubscriptionPlan } from "@Models/interfaces/plan";

export const getPlanFeatures = (
  availablePlans: ISubscriptionPlan[],
  currentPlanId: number = 2,
  desiredPlanId: number
): { before: IPlanFeature[]; after: IPlanFeature[] } => {
  if (!availablePlans || !currentPlanId || !desiredPlanId) {
    return { before: [], after: [] };
  }

  const currentPlanIndex = availablePlans.findIndex(
    (plan) => plan.id === currentPlanId
  );
  const desiredPlanIndex = availablePlans.findIndex(
    (plan) => plan.id === desiredPlanId
  );

  if (currentPlanIndex === -1 || desiredPlanIndex === -1) {
    throw new Error("Planes no encontrados");
  }

  const featuresBefore: IPlanFeature[] = [];
  let featuresAfter: IPlanFeature[] = [];

  const addUserFeature = (
    plan: ISubscriptionPlan,
    features: IPlanFeature[],
    isBoosted: boolean
  ) => {
    const userFeature: IPlanFeature = {
      name: plan.users.unlimited
        ? "Usuarios ilimitados"
        : `${plan.users.quantity} usuarios`,
      isBoosted,
    };
    features.push(userFeature);
  };

  // Añadir características hasta el plan actual en "Before"
  addUserFeature(availablePlans[currentPlanIndex], featuresBefore, false);
  featuresBefore.push(...availablePlans[currentPlanIndex].features);

  // Calcular si los usuarios del plan deseado son mejorados
  let isUserBoosted = false;
  const currentPlanUsers = availablePlans[currentPlanIndex].users;
  const desiredPlanUsers = availablePlans[desiredPlanIndex].users;

  if (desiredPlanUsers.unlimited) {
    isUserBoosted = !currentPlanUsers.unlimited;
  } else if (!currentPlanUsers.unlimited) {
    isUserBoosted = desiredPlanUsers.quantity > currentPlanUsers.quantity;
  }

  // Añadir el feature "Todos los beneficios del plan ${nombre del plan anterior}"
  if (desiredPlanIndex > 0) {
    const previousPlanName = availablePlans[desiredPlanIndex - 1].name;
    const previousPlanFeature: IPlanFeature = {
      name: `Todos los beneficios del plan ${previousPlanName}`,
      isBoosted: false,
    };
    featuresAfter.push(previousPlanFeature);
  }

  // Añadir las características del plan deseado
  addUserFeature(
    availablePlans[desiredPlanIndex],
    featuresAfter,
    isUserBoosted
  );
  featuresAfter.push(...availablePlans[desiredPlanIndex].features);

  if (desiredPlanIndex > 0) {
    featuresAfter = featuresAfter.filter((feature) => {
      return feature.name !== "Todos los beneficios anteriores";
    });
  }

  // Función para eliminar duplicados de un array de objetos
  const removeDuplicates = (arr: IPlanFeature[]) => {
    return arr.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.name === item.name)
    );
  };

  return {
    before: removeDuplicates(featuresBefore), // Para eliminar duplicados
    after: removeDuplicates(featuresAfter), // Para eliminar duplicados
  };
};
