// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SYovqj2nwB1DBDnkXVZH {
  display: grid;
  grid-template-columns: auto 35px;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 16px;

  & h3 {
    font-size: 16px;
    font-weight: 800;
    color: #45348e;
  }
}

.xketRP_KbgoxcdjQ9kBJ {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  color: #ffffff;
  border: none;
  background-color: transparent;

  &:hover {
    background-color: #f0f0f08b;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/wrapper/ModalWrapper/components/ModalHeader/styles/modalHeader.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gCAAgC;EAChC,mBAAmB;EACnB,QAAQ;EACR,gCAAgC;EAChC,oBAAoB;;EAEpB;IACE,eAAe;IACf,gBAAgB;IAChB,cAAc;EAChB;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,iCAAiC;EACjC,cAAc;EACd,YAAY;EACZ,6BAA6B;;EAE7B;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".modalHeader__container {\n  display: grid;\n  grid-template-columns: auto 35px;\n  align-items: center;\n  gap: 8px;\n  border-bottom: 1px solid #ebebeb;\n  padding-bottom: 16px;\n\n  & h3 {\n    font-size: 16px;\n    font-weight: 800;\n    color: #45348e;\n  }\n}\n\n.close_button {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 35px;\n  height: 35px;\n  border-radius: 50%;\n  cursor: pointer;\n  transition: all 150ms ease-in-out;\n  color: #ffffff;\n  border: none;\n  background-color: transparent;\n\n  &:hover {\n    background-color: #f0f0f08b;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalHeader__container": `SYovqj2nwB1DBDnkXVZH`,
	"close_button": `xketRP_KbgoxcdjQ9kBJ`
};
export default ___CSS_LOADER_EXPORT___;
