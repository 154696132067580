import React, { useContext } from "react";
import style from "./UpdatePlanPayModal.module.css";
import {
  ReportModalStateContext,
  ReportModalStateContextProps,
} from "@Hooks/ReportModalStateContext";

import PaymentProcessing from "@Components/ui/molecules/PaymentProcessing/PaymentProcessing";
import PaymentResponse from "@Components/ui/molecules/PaymentResponse/PaymentResponse";
import { usePlanPaymentHandler } from "@Hooks/usePlanPaymentHandler";
import { ISubscription, ISubscriptionPlan } from "@Models/interfaces/plan";

interface IUpdatePlanPayModalProps {
  newPlan: ISubscriptionPlan;
  newSubscription: ISubscription;
  tempType?: string;
}
const UpdatePlanPayModal: React.FC<IUpdatePlanPayModalProps> = ({
  newPlan,
  newSubscription,
  tempType,
}) => {
  const modalContext = useContext(
    ReportModalStateContext
  ) as ReportModalStateContextProps;

  const {
    paymentResponse,
    handleCreatePayment,
    handlePaymentResponse,
    handleTryAgain,
  } = usePlanPaymentHandler(modalContext, newPlan, newSubscription);

  const renderContent = () => {
    if (paymentResponse === null) {
      return (
        <PaymentProcessing
          name={`Actualizar a Plan ${newPlan.name}`}
          amount={newSubscription.prices.withIGV}
          // payer={{ email: "test_user_733384604@testuser.com" }}
          handleCreatePayment={handleCreatePayment}
          handlePaymentResponse={handlePaymentResponse}
          tempType={tempType}
        />
      );
    }

    return (
      <PaymentResponse
        paymentResponse={paymentResponse}
        handleTryAgain={handleTryAgain}
        handleClose={modalContext?.actionButtonClose}
      />
    );
  };

  return <div className={style.container}>{renderContent()}</div>;
};

export default UpdatePlanPayModal;
