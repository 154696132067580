// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.W754fuPPJtZRWwwWv3Q3 {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 700px;

  @media screen and (width < 840px) {
    width: 100%;
  }

  /* & article {
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: 100%;
	} */
}

.fG6gGktsfDRpzaEV65iX {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: #45348e;
  font-size: 1.5rem;
  overflow: hidden;
  gap: 40px;
}

.n_22SGCkKx3PAb8x9Nuk {
  width: 100%;
  display: flex;
  gap: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/BillingCard/ModalPlanEdit/ModalPlanEdit.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,YAAY;;EAEZ;IACE,WAAW;EACb;;EAEA;;;;;IAKE;AACJ;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,cAAc;EACd,iBAAiB;EACjB,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,aAAa;EACb,SAAS;AACX","sourcesContent":[".modal {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  width: 700px;\n\n  @media screen and (width < 840px) {\n    width: 100%;\n  }\n\n  /* & article {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\tgap: 20px;\n\t\twidth: 100%;\n\t} */\n}\n\n.loadingOverlay {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: #ffffff;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n  color: #45348e;\n  font-size: 1.5rem;\n  overflow: hidden;\n  gap: 40px;\n}\n\n.row {\n  width: 100%;\n  display: flex;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `W754fuPPJtZRWwwWv3Q3`,
	"loadingOverlay": `fG6gGktsfDRpzaEV65iX`,
	"row": `n_22SGCkKx3PAb8x9Nuk`
};
export default ___CSS_LOADER_EXPORT___;
